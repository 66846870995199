import request from '../utils/requestUtil'
import requestOther from '../utils/requsetOther'

function filterParam(obj) {
    for (let i in obj) {
        if (obj[i]) {
            obj[i] = obj[i].toString().trim()
        }else {
            obj[i] = ''
        }
        
    }
    return obj
}
// export function reqOdooLogin(info) {
//     return request.post('/login',info)
// }

export function reqOdooList(obj) {
    let param = filterParam(obj)
    // return request.post('/report/search?session_id='+token,obj)
    return request.post('/salesman/product/sale/data',param)
}

export function reqOdooExport(obj) {
    let param = filterParam(obj)
    // return request.post('/report/search?session_id='+token,obj)
    return request.post('/salesman/sale/data/export',param)
}