import React, { useState, useEffect } from 'react'
import './index.less'
import { DatePicker, Table, Card, Pagination, Select, message, Input, Button, Popover } from 'antd';
import { Chart, Interval, Axis, Tooltip, Geom } from 'bizcharts';
import { getWeekStartDate, getWeekEndDate, getMonthStartDate, getMonthEndDate, getQuarterEndDate, getQuarterStartDate, getToday, getYearStartDate, getYearEndDate } from '../../utils/date'
import {
    QuestionCircleOutlined
  } from '@ant-design/icons';


import { reqCustomSalePage, reqXiaowei,reqExportCustom } from '../../api/charts'
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;




let dateList = [
    { item: '今天', value: '1', active: false },
    { item: '本周', value: '2', active: false },
    { item: '本月', value: '3', active: true },
    { item: '本季度', value: '4', active: false },
    { item: '全年', value: '5', active: false }
]



const initialState = {
    start_date: getMonthStartDate(),
    end_date: getMonthEndDate(),
    sort: 'desc',
    customer_name: '',
    xw_id:''
}

export default function () {

    const [date, setDate] = useState(dateList)

    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading, setLoading] = useState(false)

    const [xiaowei, setXiaowei] = useState([])

    //list
    const [customTotal, setCustomTotal] = useState(0)
    const [dataSource, setDataSource] = useState([])
    const [dataChartSource, setDataChartSource] = useState([])
    const [sortPic, setSortPic] = useState('descend')
    let columns = [
        {
            title: '客户名称',
            dataIndex: 'partner_name',
            key: 'partner_name',
        },
        {
            title: '公司名称',
            dataIndex: 'company_name',
            key: 'company_name',
        },
        {
            title: '客户编码',
            dataIndex: 'sale_finance_code',
            key: 'sale_finance_code',
        },
        {
            title: '销售额',
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            sortOrder: sortPic,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
    ];
    async function exportData() {
        const response = await reqExportCustom(filterData);

        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.message)
                return;
            }
            window.location.href = response.data.data.url
        }else {
            message.error(response.data.message)
            return;
        }
    }
    async function loadDataPage(state = 0) {
        setLoading(true)
        // , ...filter
        setDataSource([])
        // setDataChartSource([])
        setTotal(0)
        let response;
        if (state == 1) {
            response = await reqCustomSalePage({ ...filterData, page: 1, size: size });
        }else {
            response = await reqCustomSalePage({ ...filterData, page: current, size: size });
        }

        setLoading(false)

        if (response.data.code == '-1') {
            return;
        }

        console.log(response.data.data.records)
        setDataSource(response.data.data.records)

        setTotal(response.data.data.total)

        setCustomTotal(response.data.data.total_amount)

        if (current !== 1 || filterData.sort == 'asc') {
            return;
        }
        let r = JSON.parse(JSON.stringify(response.data.data.records))
        r.forEach(element => {
            console.log(element)
            element.partner_name = element.partner_name + '(' + element.company_name.slice(0, 3) + ')'
        });
        console.log(r)
        setDataChartSource(r)
    }
    function dateChange(dates, dateStrings) {
        if (dateStrings[0] == "" && dateStrings[1] == "") {
            return;
        }
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        setDate(list)
        setFilterData({ ...filterData, start_date: dateStrings[0], end_date: dateStrings[1] })

    }
    function getDateByType(dateType, index) {
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        list[index].active = true   // 后面有useState 的set方法设置了之后会重新渲染
        setDate(list)

        let startTime = ''
        let endTime = ''
        switch (dateType) {
            case '1':
                startTime = getToday()
                endTime = getToday()
                break;
            case '2':
                startTime = getWeekStartDate()
                endTime = getWeekEndDate()
                break;
            case '3':
                startTime = getMonthStartDate()
                endTime = getMonthEndDate()
                break;
            case '4':
                startTime = getQuarterStartDate()
                endTime = getQuarterEndDate()
                break;
            case '5':
                startTime = getYearStartDate()
                endTime = getYearEndDate()
                break;
            default:
                break;
        }
        setFilterData({ ...filterData, start_date: startTime, end_date: endTime })
    }

    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)
    }
    const sizeChange = function (page, pagesize) {
        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)
    }
    const nameChange = function (e) {
        console.log(e.target.value)
        setFilterData({ ...filterData, customer_name: e.target.value })
    }
    const search = function() {
        loadDataPage(1)
    }

    const xiaoweiChange = function (value) {
        setFilterData({ ...filterData, xw_id: value })

    }
    async function loadXiaowei() {
        let res = await reqXiaowei()
        console.log(res)
        setXiaowei(res.data.data)
    }

    useEffect(() => {

        loadDataPage()
        return () => {
            console.log('unmount')
        }
    }, [current, size, filterData.start_date, filterData.end_date, filterData.xw_id, filterData.sort])

    useEffect(() => {
        loadXiaowei()
        return () => {
        }
    }, [])

    const scale = {

    };
    const disabledDate = (current)=> {
        return current && current < moment('2020-08-01',"YYYYMMDD");
    }
        const content = (
        <div>
          <p>取ERP的客户销售额</p>
        </div>
      );
    return (
        <div>
            <div className="top clearfix">
                <div className="left">
                    客户销售额
                    <div style={{display:'inline-block',padding:'0 10px'}}>
                    <Popover content={content}><QuestionCircleOutlined /></Popover>
                    </div>
                     <b>{customTotal}</b> <span>万元</span>
                    <Select defaultValue="" placeholder="请选择" style={{ width: 140 }} onChange={xiaoweiChange}>
                        <Option value="">请选择小微</Option>
                        {
                            xiaowei.map((currentValue, index) => {
                                return <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                            })
                        }

                    </Select>

                </div>
                <div className="right-box" style={{ float: 'right', paddingRight: 40 }}>
                    {
                        date.map((currentValue, index) => {
                            return <span key={index} className={currentValue.active ? 'active' : ''} onClick={() => getDateByType(currentValue.value, index)}>{currentValue.item}</span>
                        })
                    }
                日期：<RangePicker disabledDate={disabledDate} onChange={dateChange} />
                </div>
            </div>
            <div className="btm-charts">

                <div className={dataChartSource.length > 0 ? '' : 'hide'}>
                    <Chart height={400} autoFit data={dataChartSource} scale={scale} >
                        {/* <Interval position="partner_name*amount" color="#5B8FF9" size={30} shape={['partner_name*amount', (date, val) => {

                    }]} />
                    <Axis name="partner_name" visible={false} /> */}

                        <Geom type="interval" position="partner_name*amount" size={30} color="#5B8FF9"
                            tooltip={['partner_name*amount', (partner_name, amount) => {
                                return {
                                    name: '金额',
                                    value: amount + '元'
                                };
                            }]}
                        />
                        {/* <Interval position="partner_name*amount" size={30} color="#5B8FF9" /> */}
                        {/* <Axis name="partner_name" label={{
                        offset: 5,
                        rotate: 44.5,
                        autoRotate: false,
                        style: {
                            textAlign: 'start',
                            textBaseline: 'top'
                        },
                    }} /> */}

                        <Axis name="partner_name" label={{
                            autoHide: false,
                            layout: {
                                type: 'overlap',
                            },
                            formatter(params, item, index) {
                                var newParamsName = "";
                                var paramsNameNumber = params.length;
                                var provideNumber = 12;
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber);



                                if (paramsNameNumber > provideNumber) {

                                    for (var p = 0; p < rowNumber; p++) {
                                        var tempStr = "";
                                        var start = p * provideNumber;
                                        var end = start + provideNumber;

                                        if (p == rowNumber - 1) {

                                            tempStr = params.substring(start, paramsNameNumber);
                                        } else {

                                            tempStr = params.substring(start, end) + "\n";
                                        }
                                        newParamsName += tempStr;
                                    }

                                } else {

                                    newParamsName = params;
                                }

                                return newParamsName
                            },
                        }} />
                    </Chart>
                </div>

            </div>
            <div style={{ padding: 10 }}>
                客户名称： <Input placeholder="请输入客户名称" onChange={nameChange} style={{ width: 140,marginRight:20 }} />
                <Button type="primary" style={{ marginRight:20 }} onClick={search}>查询</Button>
                <Button type="primary" style={{ marginRight:20 }} onClick={exportData}>导出</Button>
                <Button type="primary" onClick={()=>{window.location.reload()}}>重置</Button>
            </div>
            <div className="btm-main">
                <Card>
                    <Table rowKey={(record, index) => record.partner_id} dataSource={dataSource} columns={columns} pagination={false} loading={loading} onChange={(pagination, filters, sorter) => {
                        //fetch service
                        console.log(pagination, filters, sorter)
                        setSortPic(sorter.order || 'descend')
                        let tmpSort = ''
                        if (sorter.order == 'ascend') {
                            tmpSort = 'asc'
                        } else if (sorter.order == 'descend') {
                            tmpSort = 'desc'
                        } else {
                            tmpSort = 'desc'
                        }
                        setFilterData({ ...filterData, sort: tmpSort })
                    }} />
                </Card>
                <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
} 