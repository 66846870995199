import React,{Component} from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import Login from './pages/login/login'
import Dadmin from './pages/demo/admin'
import Demo from './pages/demo/demo'
import Test from './pages/demo/test';
import Admin from './pages/admin/admin';
// import {Button,message} from 'antd';
// function App() {
//   return (
//     <div className="App">
//         app
//     </div>
//   );
// }
// export default App;

export default class App extends Component {
  // handleClick = ()=> {
  //   message.success('success')
  // }
  render() {
    // return <Button type="primary" onClick={this.handleClick}>Primary Button</Button>
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={Login}></Route>
          <Route path='/demo' component={Demo}></Route>
          <Route path='/dadmin' component={Dadmin}></Route>
          <Route path='/test' component={Test}></Route>
          <Route path='/' component={Admin}></Route>
        </Switch>
        
      </BrowserRouter>
    )
  }
}

