import React, { useState, useEffect } from 'react'
import './index.less'
import { DatePicker, Table, Card, Pagination, Select, message, Input, Button, Popover } from 'antd';
import { Chart, Interval, Axis, Geom } from 'bizcharts';
import { getWeekStartDate, getWeekEndDate, getMonthStartDate, getMonthEndDate, getQuarterEndDate, getQuarterStartDate, getToday, getYearStartDate, getYearEndDate } from '../../utils/date'

import {
    QuestionCircleOutlined
  } from '@ant-design/icons';


import { reqSaleAmount, reqSaleAmountPage, reqBrand, reqCategory,reqExportMoney } from '../../api/charts'
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;




let dateList = [
    { item: '今天', value: '1', active: false },
    { item: '本周', value: '2', active: false },
    { item: '本月', value: '3', active: true },
    { item: '本季度', value: '4', active: false },
    { item: '全年', value: '5', active: false }
]




const initialState = {
    start_date: getMonthStartDate(),
    end_date: getMonthEndDate(),
    product_brand: '',
    product_category: '',
    product_name: '',
    product_code: ''
}

export default function () {
    const [date, setDate] = useState(dateList)


    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading, setLoading] = useState(false)

    const [topData, setTopData] = useState([])


    const [brand, setBrand] = useState([])
    const [category, setCategory] = useState([])

    const [categoryDefaultValue, setCategoryDefaultValue] = useState('')

    const [chartType, setChartType] = useState(true)
    const [totalMoney, setTotalMoney] = useState(0)

    //list
    const [dataSource, setDataSource] = useState([])

    const [sort, setSort] = useState('desc')
    const [sortPic, setSortPic] = useState('descend')
    const columns = [
        // {
        //     title: '排名',
        //     dataIndex: 'product_id',
        //     key: 'product_id',
        // },
        {
            title: '品牌',
            dataIndex: 'product_brand',
            key: 'product_brand',
        },
        {
            title: '商品名称',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: '物料编码',
            dataIndex: 'default_code',
            key: 'default_code',
        },
        {
            title: '规格型号',
            dataIndex: 'specification_code',
            key: 'specification_code',
        },
        {
            title: '销售额',
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            sortOrder: sortPic,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
    ];
    async function exportData() {
        const response = await reqExportMoney(filterData);

        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.message)
                return;
            }
            window.location.href = response.data.data.url
        }else {
            message.error(response.data.message)
            return;
        }
    }
    async function loadData() {
        setTopData([])
        if (filterData.product_brand && filterData.product_category == "") {
            return
        }
        
        const response = await reqSaleAmount({ start_date:filterData.start_date,end_date:filterData.end_date,product_brand:filterData.product_brand,product_category:filterData.product_category });
        if (response.data.code == '-1') {
            return;
        }
        let chart = true
        if (response.data.data.type == "brand") {
            chart = true
        } else {
            chart = false
        }
        setChartType(chart)


        setTotalMoney(response.data.data.all_amount)
        setTopData(response.data.data.records)


    }
    async function loadDataPage(state = 0) {
        setLoading(true)
        // , ...filter
        setDataSource([])
        setTotal(0)
        if (filterData.product_brand && filterData.product_category == "") {
            return
        }
        let response;
        if (state == 1) {
            response = await reqSaleAmountPage({ ...filterData, page: 1, size: size, sort: sort });
        }else {
            response = await reqSaleAmountPage({ ...filterData, page: current, size: size, sort: sort });
        }

        setLoading(false)
        if (response.data.code == '-1') {
            return;
        }
        setDataSource(response.data.data.records)
        setTotal(response.data.data.total)


    }
    function dateChange(dates, dateStrings) {
        if (dateStrings[0] == "" && dateStrings[1] == "") {
            return;
        }
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        setDate(list)
        setFilterData({ ...filterData, start_date: dateStrings[0], end_date: dateStrings[1] })

    }
    function getDateByType(dateType, index) {
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        list[index].active = true   // 后面有useState 的set方法设置了之后会重新渲染
        setDate(list)

        let startTime = ''
        let endTime = ''
        switch (dateType) {
            case '1':
                startTime = getToday()
                endTime = getToday()
                break;
            case '2':
                startTime = getWeekStartDate()
                endTime = getWeekEndDate()
                break;
            case '3':
                startTime = getMonthStartDate()
                endTime = getMonthEndDate()
                break;
            case '4':
                startTime = getQuarterStartDate()
                endTime = getQuarterEndDate()
                break;
            case '5':
                startTime = getYearStartDate()
                endTime = getYearEndDate()
                break;
            default:
                break;
        }
        setFilterData({ ...filterData, start_date: startTime, end_date: endTime })
    }

    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)
    }
    const sizeChange = function (page, pagesize) {
        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)
    }
    const brandChange = function (value) {
        setFilterData({ ...filterData, product_brand: value })
        // let v = value == "" ? "" : "请选择"
        // setCategoryDefaultValue(v)
        if (value !== "") {
            return
        }
        setCategoryDefaultValue("")
    }
    useEffect(() => {
        setFilterData({ ...filterData, product_category: '' })
        setCategory([])
        setCategoryDefaultValue('')
        loadCategory()

        return () => {

        }
    }, [filterData.product_brand])


    const categoryChange = function (value, opt) {

        setFilterData({ ...filterData, product_category: value })

        setCategoryDefaultValue(value)   //opt.children
    }
    async function loadBrand() {
        let res = await reqBrand()
        if (res.data.code == '-1') {
            return;
        }
        console.log(res)
        setBrand(res.data.data.records)
    }
    async function loadCategory() {
        if (!filterData.product_brand) {
            return;
        }
        let res = await reqCategory({ product_brand: filterData.product_brand })
        if (res.data.code == '-1') {
            return;
        }
        let id = res.data.data.records.length > 0 ? res.data.data.records[0].id : ''
        if (id == '') {
            setLoading(false)
        }
        setCategoryDefaultValue(id)
        setCategory(res.data.data.records)
        setFilterData({ ...filterData, product_category: id })
    }
    const nameChange = function (e) {
        console.log(e.target.value)
        setFilterData({ ...filterData, product_name: e.target.value })
    }
    const codeChange = function (e) {
        console.log(e.target.value)
        setFilterData({ ...filterData, product_code: e.target.value })
    }
    const search = function() {
        loadDataPage(1)
    }
    useEffect(() => {
        loadData()
        loadDataPage()
        return () => {
            console.log('unmount')
        }
    }, [filterData.start_date, filterData.end_date, filterData.product_brand, filterData.product_category])
    useEffect(() => {
        loadDataPage()
        return () => {

        }
    }, [current, size, sort])
    useEffect(() => {
        loadBrand()
        return () => {
        }
    }, [])
    const scale = {

    };
    const disabledDate = (current)=> {
        return current && current < moment('2020-08-01',"YYYYMMDD");
    }

    const content = (
        <div>
          <p>取ERP的销售总额减去退款</p>
        </div>
      );
    return (
        <div>
            <div className="top clearfix">
                <div className="left">
                    商品销售额统计
                    <div style={{display:'inline-block',padding:'0 10px'}}>
                    <Popover content={content}><QuestionCircleOutlined /></Popover>
                    </div>
                     <b>{totalMoney}</b> <span>万元</span>
                    <Select defaultValue="" placeholder="请选择" style={{ width: 140 }} onChange={brandChange}>
                        <Option value="">全部品牌</Option>
                        {
                            brand.map((currentValue, index) => {
                                return <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                            })
                        }

                    </Select>
                    <Select value={categoryDefaultValue} placeholder="请选择" style={{ width: 140, marginLeft: 20 }} onChange={categoryChange}>
                        {
                            category.length > 0 ?
                                category.map((currentValue, index) => {
                                    return <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                })
                                :
                                ''
                        }
                    </Select>
                </div>
                <div className="right-box" style={{ float: 'right', paddingRight: 40 }}>
                    {
                        date.map((currentValue, index) => {
                            return <span key={index} className={currentValue.active ? 'active' : ''} onClick={() => getDateByType(currentValue.value, index)}>{currentValue.item}</span>
                        })
                    }
                日期：<RangePicker disabledDate={disabledDate} onChange={dateChange} />
                </div>
            </div>
            <div className="btm-charts">
                <div className={chartType ? '' : 'hide'}>
                    <div className={topData.length > 0 ? '' : 'hide'}>
                        <Chart height={200} autoFit data={topData} scale={scale} >
                            {/* <Interval position="product_brand*amount" size={30} /> */}
                            <Geom type="interval" position="product_brand*amount" size={30} color="#5B8FF9"

                                tooltip={['product_brand*amount', (product_brand, amount) => {
                                    return {
                                        name: '金额',
                                        value: amount + '元'
                                    };
                                }]}
                            />
                        </Chart>
                    </div>

                </div>

                <div className={!chartType ? '' : 'hide'}>
                    <div className={topData.length > 0 ? '' : 'hide'}>
                        <Chart height={400} autoFit data={topData} scale={scale} >
                            {/* <Interval position="name*amount" color="#5B8FF9" size={30} shape={['name*amount', (date, val) => {

                        }]} /> */}
                            {/* <Interval position="name*amount" color="#5B8FF9" size={30} /> */}
                            <Geom type="interval" position="name*amount" size={30} color="#5B8FF9"

                                tooltip={['name*amount', (name, amount) => {
                                    return {
                                        name: '金额',
                                        value: amount + '元'
                                    };
                                }]}
                            />
                            {/* <Axis name="name" label={{
                            offset: 5,
                            rotate: 44.5,
                            autoRotate: false,
                            style: {
                                textAlign: 'start',
                                textBaseline: 'top'
                            }
                        }} /> */}
                            <Axis name="name" label={{
                                autoHide: false,
                                formatter(params, item, index) {
                                    var newParamsName = "";
                                    var paramsNameNumber = params.length;
                                    var provideNumber = 12;
                                    var rowNumber = Math.ceil(paramsNameNumber / provideNumber);



                                    if (paramsNameNumber > provideNumber) {

                                        for (var p = 0; p < rowNumber; p++) {
                                            var tempStr = "";
                                            var start = p * provideNumber;
                                            var end = start + provideNumber;

                                            if (p == rowNumber - 1) {

                                                tempStr = params.substring(start, paramsNameNumber);
                                            } else {

                                                tempStr = params.substring(start, end) + "\n";
                                            }
                                            newParamsName += tempStr;
                                        }

                                    } else {

                                        newParamsName = params;
                                    }

                                    return newParamsName
                                },
                            }} />

                        </Chart>
                    </div>
                </div>
            </div>
            <div style={{ padding: 10 }}>
                商品名称： <Input placeholder="请输入名称" onChange={nameChange} style={{ width: 140, marginRight: 20 }} />
                物料编码： <Input placeholder="请输入编码" onChange={codeChange} style={{ width: 140, marginRight: 20 }} />
                <Button type="primary" style={{marginRight:20}} onClick={search}>查询</Button>
                <Button type="primary" style={{marginRight:20}} onClick={exportData}>导出</Button>
                <Button type="primary" onClick={()=>{window.location.reload()}}>重置</Button>
            </div>
            <div className="btm-main">
                <Card>
                    <Table rowKey={(record, index) => record.product_id} dataSource={dataSource} columns={columns} pagination={false} loading={loading} onChange={(pagination, filters, sorter) => {
                        //fetch service
                        console.log(pagination, filters, sorter)
                        setSortPic(sorter.order || 'descend')
                        let tmpSort = ''
                        if (sorter.order == 'ascend') {
                            tmpSort = 'asc'
                        } else if (sorter.order == 'descend') {
                            tmpSort = 'desc'
                        } else {
                            tmpSort = 'desc'
                        }
                        setSort(tmpSort)
                    }} />
                </Card>
                <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
} 