import request from '../utils/requestUtil'
function filterParam(obj) {
    for (let i in obj) {
        if (obj[i]) {
            obj[i] = obj[i].toString().trim()
        }else {
            obj[i] = ''
        }
        
    }
    return obj
}
export function reqOrderDetail(saleInfo) {
    let param = filterParam(saleInfo)
    return request.post('/trade/delivery/detail',param)
}

export function reqProductOut(params) {
    let param = filterParam(params)
    return request.post('/product/delivery/out',param)
}

export function reqProductOutExport(params) {
    let param = filterParam(params)
    return request.post('/product/delivery/out/export',param)
}

export function reqShopList() {
    return request.get('/shop')
}

export function reqFirstCate() {
    // return request.get('/first/category')
    return request.post('/product/category')
}

export function reqSecondCate(pidInfo) {
    let param = filterParam(pidInfo)
    // return request.get('/second/category',param)
    return request.post('/product/second/category',param)
}

export function reqExport(saleInfo) {
    let param = filterParam(saleInfo)
    return request.post('/trade/delivery/detail/download',param)
}