import axios from 'axios';
import qs from 'qs'
import { message } from 'antd'


// 新创建一个axios实例，并进行基础配置
var instance = axios.create({
    // baseURL: 'http://192.168.0.87:8069/api',
    baseURL: 'http://erp.haierbaby.com/api',
    timeout: 10000,
    // headers: {'X-Requested-With': 'XMLHttpRequest'}
});

// 添加请求拦截器
instance.interceptors.request.use((config) => {
    // 再次设置tkoen或者添加loading等请求前的操作
    // if (localStorage.getItem('Authorization')) {
    //     config.headers.Authorization = localStorage.getItem('Authorization');
    // }
    return config;
})

// 添加xi响应拦截器
instance.interceptors.response.use(
    (response) => {
        //响应数据后做点什么
        if (response.data.code === 1001) {
            localStorage.clear()
            sessionStorage.clear()
            // window.location.href = window.location.origin + '/login'
            window.location.href = process.env.REACT_APP_AUTH_PATH
            // window.location.href = 'http://www.baidu.com'
        }

        return response;
    },
    (error) => {
        // 对响应错误做点什么
        message.error('请求出错：' + error.message)
        return Promise.reject(error);
    }
)


/**
 * get请求
 * @method get
 * @param {url, params, loading} 请求地址，请求参数，是否需要加载层
 */
var get = function (url, params) {
    return new Promise((resolve, reject) => {
        // {
        //   params: params
        // }
        instance
            .get(url, { params: params })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                // https://www.bilibili.com/video/BV1st41137hg?p=24
                reject(err);    //这里不调用reject 统一处理异常方法
            });
    });
};
/**
 * post请求  post json
 * @method post  
 * @param {url, params} 请求地址，请求参数，是否需要加载层
 */
var post = function (url, data = {}) {
    return new Promise((resolve, reject) => {
        // qs.stringify(data)
        instance
            .post(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                console.log(res);
                resolve(res);
            })
            .catch(err => {
                reject(err);    //这里不调用reject 统一处理异常方法
            });
    });
};
export default { get, post };