import React, { useState, useEffect } from 'react'
import './index.less'
import { DatePicker,Popover } from 'antd';

import { Chart, Interval, Geom } from 'bizcharts';
import { reqSquare } from '../../api/home'
import { mul, add } from '../../utils/floorMath'

import {
    QuestionCircleOutlined
  } from '@ant-design/icons';
import { getWeekStartDate, getWeekEndDate, getMonthStartDate, getMonthEndDate, getQuarterEndDate, getQuarterStartDate, getToday, getYearStartDate, getYearEndDate } from '../../utils/date'
import moment from 'moment';
const { RangePicker } = DatePicker;


let dateList = [
    { item: '今天', value: '1', active: false },
    { item: '本周', value: '2', active: false },
    { item: '本月', value: '3', active: true },
    { item: '本季度', value: '4', active: false },
    { item: '全年', value: '5', active: false }
]

const initialState = {
    start_date: getMonthStartDate(),
    end_date: getMonthEndDate()
}

export default function () {
    const [filterData, setFilterData] = useState({})
    const [totalAll, setTotalAll] = useState(0)
    const [date, setDate] = useState(dateList)
    const [squareData, setSquareData] = useState([]);
    async function loadDataSquare() {


        const response = await reqSquare({ start_date: filterData.start_date, end_date: filterData.end_date });


        if (!response.data.data) {
            return;
        }
        let res = response.data.data;
        let total = 0
        total = res.reduce((prev, item) => {
            return add(prev,item.amount)
        }, 0)
        console.log(total)
        res.forEach((currentValue, index) => {
            let per = (Math.round((currentValue.amount / total) * 10000) / 10000)

            let mulRes = mul(per, 100)

            Object.assign(currentValue, { percent: mulRes + '%' })
            currentValue.amount = Math.floor(currentValue.amount * 100) / 100

            // currentValue = {...currentValue,percent:currentValue.amount/total}
        })
        console.log(res)
        setSquareData(res)

        // let all = res.reduce((prev, item) => {
        //     console.log(prev, item)
        //     return add(prev, item.amount)
        // }, 0)
        setTotalAll(total.toFixed(2))
        console.log(squareData, 'setSquareData 为什么不会及时获取到呢 但是数据显示没问题 https://blog.csdn.net/aminwangaa/article/details/107379851')
    }
    function dateChange(dates, dateStrings) {
        if (dateStrings[0] == "" && dateStrings[1] == "") {
            return;
        }
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        setDate(list)
        setFilterData({ ...filterData, start_date: dateStrings[0], end_date: dateStrings[1] })

    }
    function getDateByType(dateType, index) {
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        list[index].active = true   // 后面有useState 的set方法设置了之后会重新渲染
        setDate(list)

        let startTime = ''
        let endTime = ''
        switch (dateType) {
            case '1':
                startTime = getToday()
                endTime = getToday()
                break;
            case '2':
                startTime = getWeekStartDate()
                endTime = getWeekEndDate()
                break;
            case '3':
                startTime = getMonthStartDate()
                endTime = getMonthEndDate()
                break;
            case '4':
                startTime = getQuarterStartDate()
                endTime = getQuarterEndDate()
                break;
            case '5':
                startTime = getYearStartDate()
                endTime = getYearEndDate()
                break;
            default:
                break;
        }
        setFilterData({ ...filterData, start_date: startTime, end_date: endTime })
    }

    useEffect(() => {

        loadDataSquare()
        return () => {

        }
    }, [filterData.start_date, filterData.end_date])

    const scale = {
        "amount": {
            type: "log",
            max: 10000
        },
    };
    const disabledDate = (current)=> {
        return current && current < moment('2020-08-01',"YYYYMMDD");
    }
    const content = (
        <div>
          <p>取K3发货的订单</p>
        </div>
      );
    return (
        <div>
            <div className="top clearfix">
                <div className="left">
                    出库总金额
                    <div style={{display:'inline-block',padding:'0 10px'}}>
                    <Popover content={content}><QuestionCircleOutlined /></Popover>
                    </div>
                     <b>{totalAll}</b> <span>万元</span>
                </div>
                <div className="right-box" style={{ float: 'right', paddingRight: 40 }}>
                    {
                        date.map((currentValue, index) => {
                            return <span key={index} className={currentValue.active ? 'active' : ''} onClick={() => getDateByType(currentValue.value, index)}>{currentValue.item}</span>
                        })
                    }
                日期：<RangePicker disabledDate = {disabledDate} onChange={dateChange} />
                </div>
            </div>
            <div className="btm-charts">
                <div className={squareData.length > 0 ? '' : 'hide'}>
                    <Chart height={300} autoFit data={squareData} scale={scale} >
                        {/* <Interval position="brand*amount" size={30} /> */}
                        <Geom type="interval" position="brand*amount" size={30} color="#5B8FF9"
                            tooltip={['brand*amount', (brand, amount) => {
                                return {
                                    name: '金额',
                                    value: amount + '万元'
                                };
                            }]}
                        />
                    </Chart>
                </div>
            </div>
        </div>
    )
}
