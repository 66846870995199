import request from '../utils/requestUtil'
function filterParam(obj) {
    for (let i in obj) {
        if (obj[i]) {
            obj[i] = obj[i].toString().trim()
        }else {
            obj[i] = ''
        }
        
    }
    return obj
}

export function reqBrandList() {
    return request.post('/product/brand')
}

export function reqFirstCate() {
    return request.post('/product/category')
}


export function reqSecondCate(obj) {
    let param = filterParam(obj)
    return request.post('/product/second/category',param)
}


export function reqReturn(obj) {
    let param = filterParam(obj)
    return request.post('/sale/return/detail/page',param)
}


export function reqExportReturn(obj) {
    let param = filterParam(obj)
    return request.post('/sale/return/detail/export',param)
}
