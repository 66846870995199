import request from '../utils/requestUtil'

function filterParam(obj) {
    for (let i in obj) {
        if (obj[i]) {
            obj[i] = obj[i].toString().trim()
        }else {
            obj[i] = ''
        }
        
    }
    return obj
}

//goodssalemoney
export function reqSaleAmount(obj) {
    let param = filterParam(obj)
    return request.post('/product/sale/amount',param)
}

export function reqSaleAmountPage(obj) {
    let param = filterParam(obj)
    return request.post('/product/sale/amount/page',param)
}

export function reqBrand() {
    return request.post('/product/brand')
}

export function reqCategory(obj) {
    let param = filterParam(obj)
    return request.post('/product/category',param)
}

export function reqExportMoney(obj) {
    let param = filterParam(obj)
    return request.post('/product/sale/amount/qty/page/export',param)
}




//goodssaleamount
export function reqSaleQty(obj) {
    let param = filterParam(obj)
    return request.post('/product/sale/qty',param)
}
export function reqSaleQtyPage(obj) {
    let param = filterParam(obj)
    return request.post('/product/sale/qty/page',param)

}


//customsalemoney
export function reqCustomSalePage(obj) {
    let param = filterParam(obj)
    return request.post('/partner/sale/data/page',param)
}


//salereturn
export function reqReturnDetailPage(obj) {
    let param = filterParam(obj)
    return request.post('/sale/return/detail/page',param)
}


export function reqExportCustom(obj) {
    let param = filterParam(obj)
    return request.post('/partner/sale/data/page/export',param)
}

export function reqXiaowei() {
    return request.get('/business/entity')
}