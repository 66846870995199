import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Card, Table, Pagination, message } from 'antd'

import OrderDetailFilter from '../../components/orderdetailfilter'
import { reqOrderDetail,reqExport } from '../../api/orderdetail'

import './index.less'

const columns = [
    // {
    //     title: '日期',
    //     dataIndex: 'create_date',
    // },
    {
        title: '小微',
        dataIndex: 'xw_name',
    },
    {
        title: '所属部门',
        dataIndex: 'sale_dept_name',
    },
    {
        title: '店铺名称',
        dataIndex: 'partner_name',
    },
    {
        title: '店铺代码',
        dataIndex: 'partner_code',
    },
    {
        title: '订单编号',
        dataIndex: 'order_name',
    },
    {
        title: '平台单号',
        dataIndex: 'platform_code',
    },
    // 后台没返回
    // {
    //     title: '付款时间',
    //     dataIndex: 'pay_time',
    // },
    {
        title: '发货时间',
        dataIndex: 'delivery_date',
    },
    {
        title: '物流单号',
        dataIndex: 'logistics',
    },
    // {
    //     title: '发货状态',
    //     dataIndex: 'if_delivery',
    // },
    // {
    //     title: '物流单号',
    //     dataIndex: 'express_no',
    // },
    // {
    //     title: '客户名称',
    //     dataIndex: 'customer_name',
    // },
    {
        title: '商品名称',
        dataIndex: 'product_name',
    },
    {
        title: '商品分类',
        dataIndex: 'product_category',
    },
    {
        title: '商品代码',
        dataIndex: 'product_code',
    },
    {
        title: '规格编码',
        dataIndex: 'sku_code',
    },
    {
        title: '是否赠品',
        dataIndex: 'if_gift',
        render (res) {
            if (!res) {
                return '否'
            }else {
                return '是'
            }
        }
    },
    {
        title: '数量',
        dataIndex: 'product_qty',
    },
    // {
    //     title: '标准单价',
    //     dataIndex: '',
    // },
    {
        title: '实际单价',
        dataIndex: 'real_price_unit',
    },
    // {
    //     title: '标准总价',
    //     dataIndex: '',
    // },
    {
        title: '金额',
        dataIndex: 'amount',
    },
    // {
    //     title: '折后金额',
    //     dataIndex: 'amount_after',
    // },
    // {
    //     title: '备注',
    //     dataIndex: 'memo ',
    // },
    // ...[{}],
];

const date = new Date()
let y = date.getFullYear()
let m = Number(date.getMonth()) + 1 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
let d = Number(date.getDate()) >= 10 ? Number(date.getDate()) : '0' + Number(date.getDate())
let today = y + '-' + m + '-' + d
let first = y + '-' + m + '-' + '01'

const initialState = {
    // start_pay_time: '',
    // end_pay_time: '',
    start_send_time: first,
    end_send_time: today,
    customer_numbers: '',
    order_code: '',
    plant_code: '',
    // send_goods_status: '',
    // express_code:'',
    f_cate_id: '',
    s_cate_id: '',
    pro_code: '',
    size_code: '',
    vip_name: ''
}

export default function () {

    const [data, setData] = useState([])

    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading,setLoading] = useState(false)

    async function loadData(filterData,state=0) {
        setLoading(true)
        setData([])
        setTotal(0)
        // , ...filter
        let response;
        if (state == 1) {
            response = await reqOrderDetail({ page_no: 1, page_size: size, ...filterData });
        }else {
            response = await reqOrderDetail({ page_no: current, page_size: size, ...filterData });
        }

        setLoading(false)

        if (response.data && response.data.data && response.data.code != '-1') {
            if ( response.data.data.records) {
                response.data.data.records.forEach((item, index) => {

                    Object.assign(item, { tmpkey: index })
                })
            }else {
                console.log('空数据')
            }

        } else {
            console.log(response.data.message)
            // message.error(response.data.message)
            return;
        }

        setData(response.data.data.records)
        setTotal(response.data.data.length)
    }

    useEffect(() => {
        loadData(filterData)
        return () => {
            console.log('unmount')
        }
    }, [current, size])
    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)
    }
    const sizeChange = function (page, pagesize) {
        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)
    }

    const childRef = useRef()
    function getFilter() {
        // 也可以这样写https://segmentfault.com/q/1010000019538336
        // setFilterData(()=> {
        //     const nextParam = childRef.current.filter
        //     loadData(nextParam)
        //     return nextParam
        // })
        console.log(childRef.current.filter)
        setFilterData(childRef.current.filter)
        loadData(childRef.current.filter,1)
    }

    function changeParentFilter() {
        setFilterData(childRef.current.filter)
    }

    async function exportData() {
        const response = await reqExport(filterData);

        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.message)
                return;
            }
            window.location.href = response.data.data.url
        }else {
            message.error(response.data.message)
            return;
        }
    }
    return (

        <div>
            <OrderDetailFilter ref={childRef} onFilter={getFilter} onExport={exportData} onChangeFilter={changeParentFilter} data={filterData}></OrderDetailFilter>
            {/* <SaleOrderFilter ref={childRef} onFilter={getFilter} onChangeFilter={changeParentFilter} data={filterData}></SaleOrderFilter> */}
            <Card>
                <Table rowKey={(record, index) => record.tmpkey} bordered dataSource={data} columns={columns} loading={loading} pagination={false} scroll={{ x: 2600 }} />
                <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div>

            </Card>
        </div>
    )
}
