import React, {useState, useContext} from 'react'
import {Button} from 'antd'

const themeContext = React.createContext('#000')


// function End(props) {
//     const {bgColor} = props
//     const style = {
//         backgroundColor: bgColor
//     }
//     return (
//         <div style={style}>child</div>
//     )
// }

// function Child(props) {
//     console.log(props)
//     const {bgColor} = props;
    
//     return (
//         <div>
            
//             <End bgColor={bgColor}></End>
//         </div>
//     )
// }

function End() {
    const bgColor = useContext(themeContext)
    const style = {
        backgroundColor: bgColor
    }
    return (
        <div style={style}>child</div>
    )
}

function Child() {
    return (
        <div>
            <End></End>
        </div>
    )
}

function Demo() {
    const [bgColor,setBgColor] = useState('#000')
    console.log(bgColor,123)
    return(
        <div>
            <Button type="primary" onClick={()=>{setBgColor('#dedede')}}>点击</Button>
            {/* <Child bgColor={bgColor}></Child> */}
            <themeContext.Provider value={bgColor}>
                <Child></Child>
            </themeContext.Provider>
            
        </div>
    )
}

export default Demo;






// function Child(props) {
//     const {children} = props
//     return (
//         <div className="test">{children}</div>
//     )
// }
// function End() {
//     const style = {
//         backgroundColor: '#000'
//     }
//     return (
//         <div style={style}>child</div>
//     )
// }
// function Demo() {
//     const [bgColor,setBgColor] = useState('#000')
//     return(
//         <Child>
//             <End></End>
//         </Child>
//     )
// }
// export default Demo;