import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Table,Select, Pagination, message, DatePicker, Button, Input } from 'antd'
import moment from 'moment'
import { reqList, reqShopList,reqExport,reqExportDetail } from '../../api/prototal'
const { Option } = Select;

const { RangePicker } = DatePicker;
const columns = [
    {
        title: '客户名称',
        dataIndex: 'shop_name',
    },
    {
        title: '物料编码',
        dataIndex: 'item_code',
    },
    {
        title: '产品名称',
        dataIndex: 'item_name',
    },
    {
        title: '发货仓库',
        dataIndex: 'warehouse_name',
    },
    {
        title: '数量',
        dataIndex: 'qty',
    }
];

const date = new Date()
let y = date.getFullYear()
let m = Number(date.getMonth()) + 1 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
let d = Number(date.getDate()) >= 10 ? Number(date.getDate()) : '0' + Number(date.getDate())
let today = y + '-' + m + '-' + d
let first = y + '-' + m + '-' + '01'

const initialState = {
    start_date: first,
    end_date: today,
    shop_code: "",
    warehouse_code: "",
    product_code: "",
    product_name: ""
}
function Prototal() {
    const [data, setData] = useState([])
    const [custom,setCustom] = useState([])

    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading, setLoading] = useState(false)
    async function loadData() {
        setLoading(true)
        setData([])
        setTotal(0)
        const res = await reqList({
            ...filterData,
            // "offset": current,   无分页
            // "limit": size
        })

        setLoading(false)
        if (res.data && res.data.data) {
            setData(res.data.data)

        } else {
            console.log('error')
            message.error(res.data.msg)
        }
        
    }
    async function loadShop() {
        const res = await reqShopList()
        // console.log(res.data.data);
        if (res.data.data) {
            setCustom(res.data.data)
        }
    }

    async function exportData() {

        const response = await reqExport({
            ...filterData
        })
        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.msg)
                return;
            }
            window.location.href = response.data.data
        }else {
            message.error(response.data.msg)
            return;
        }

    }
    const exportDetail = async function () {
        const response = await reqExportDetail({
            ...filterData
        })
        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.msg)
                return;
            }
            window.location.href = response.data.data
        }else {
            message.error(response.data.msg)
            return;
        }
    }

    const customChange = (value)=>{
        console.log(value);
        setFilterData({...filterData,shop_code:value})
    }
    const storeChange = (value)=>{
        setFilterData({...filterData,warehouse_code:value})
        console.log(value);
    }
    const wlCodeChange = (e)=>{
        console.log(e.target.value);
        setFilterData({...filterData,product_code:e.target.value})

    }
    const proNameChange = (e)=>{
        console.log(e.target.value);
        setFilterData({...filterData,product_name:e.target.value})
    }

    useEffect(() => {

        loadData()
        loadShop()
        return () => {
            console.log('unmount')
        }
    }, [current, size])
    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)
    }
    const sizeChange = function (page, pagesize) {

        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)
    }

    const style = { padding: '10px' }
    const dateFormat = 'YYYY-MM-DD';
    const sendDateChange = function (dates, dateStrings) {
        let tmpdata = { ...filterData, start_date: dateStrings[0], end_date: dateStrings[1] }
        setFilterData(tmpdata)
        console.log(filterData, 'filterData 为什么不会及时获取到呢 但是数据显示没问题 https://blog.csdn.net/aminwangaa/article/details/107379851')
    }
    const onFilter = function () {
        loadData()
    }


    const disabledDate = (current)=> {
        return current && current < moment('2020-08-01',"YYYYMMDD");
    }
    return (
        <div>
            <div style={{padding:'10px'}}>取管易数据</div>
            <Row>
                <Col span={24}>
                    <div style={style}>
                        发货日期：<RangePicker onChange={sendDateChange} disabledDate={disabledDate} defaultValue={[moment(filterData.start_date, dateFormat), moment(filterData.end_date, dateFormat)]} picker={'day'} />
                    </div>
                </Col>
                
            </Row>
            <Row>
                {/* <Col span={6}>
                    <div style={style}>
                        客户名称：
                        <Select defaultValue="" allowClear showSearch placeholder="请选择" style={{ width: 150 }} onChange={customChange}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>

                            {
                                custom.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.shop_code} value={currentValue.shop_code}>{currentValue.shop_name}</Option>
                                    )
                                })
                            }

                        </Select>
                    </div>
                </Col> */}
                <Col span={6}>
                    <div style={style}>
                        发货仓库：
                        <Select defaultValue="" allowClear placeholder="请选择" style={{ width: 150 }} onChange={storeChange}>
                            <Option value="KS002">昆山海尔婴童仓</Option>
                            <Option value="QHBCK012">佛山嘉荣仓</Option>
                        </Select>
                    </div>
                </Col>
                <Col span={6}>
                    <div style={style}>
                        物料编码：<Input placeholder="请输入物料编码" onChange={wlCodeChange} style={{ width: 160 }} />
                    </div>
                </Col>
                <Col span={6}>
                    <div style={style}>
                        产品名称：<Input placeholder="请输入产品名称" onChange={proNameChange} style={{ width: 160 }} />
                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div style={style}>
                        <Button type="primary" style={{ marginRight: 20 }} onClick={onFilter}>查询</Button>
                        <Button type="primary" style={{ marginRight: 20 }} onClick={exportData}>导出汇总</Button>
                        <Button type="primary" style={{ marginRight: 20 }} onClick={exportDetail}>导出汇总明细</Button>
                        <Button type="primary" onClick={() => { window.location.reload() }}>重置</Button>
                    </div>
                </Col>
                
            </Row>
            <Card>
                <Table rowKey={(record, index) => record.item_code} bordered dataSource={data} columns={columns} loading={loading} pagination={false} />
                {/* <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div> */}

            </Card>
        </div>
    )
}

export default Prototal