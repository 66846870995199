import React, { useState, useEffect, useRef, useCallback } from 'react'
import {DatePicker, Radio, Card, Table, Pagination, message, Col, Button, Row, Popover} from 'antd'

import { reqProductOut,reqProductOutExport } from '../../api/orderdetail'

import './index.less'
import moment from "moment";
import {QuestionCircleOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const columns = [
    // {
    //     title: '日期',
    //     dataIndex: 'create_date',
    // },
    {
        title: '产品编码',
        dataIndex: 'product_code',
    },
    {
        title: '产品名称',
        dataIndex: 'product_name',
    },
    {
        title: '规格型号',
        dataIndex: 'product_specification',
    },
    {
        title: '品牌',
        dataIndex: 'brand_name',
    },
    {
        title: '出库数量',
        dataIndex: 'out_qty',
    }

];

const date = new Date()
let y = date.getFullYear()
let m = Number(date.getMonth()) + 1 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
let d = Number(date.getDate()) >= 10 ? Number(date.getDate()) : '0' + Number(date.getDate())
let today = y + '-' + m + '-' + d
let first = y + '-' + m + '-' + '01'

const initialState = {
    // start_pay_time: '',
    // end_pay_time: '',
    start_date: first,
    end_date: today,
    oper_type:''
}

export default function () {

    const [data, setData] = useState([])

    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading,setLoading] = useState(false)

    async function loadData(filterData,state=0) {
        setLoading(true)
        setData([])
        setTotal(0)
        // , ...filter
        let response;
        if (state == 1) {
            response = await reqProductOut({ page_no: 1, page_size: size, ...filterData });
        }else {
            response = await reqProductOut({ page_no: current, page_size: size, ...filterData });
        }

        setLoading(false)
        
        if (response.data && response.data.data && response.data.code != '-1') {
            if ( response.data.data.records) {
                response.data.data.records.forEach((item, index) => {

                    Object.assign(item, { tmpkey: index })
                })
            }else {
                console.log('空数据')
            }

        } else {
            console.log(response.data.message)
            // message.error(response.data.message)
            return;
        }

        setData(response.data.data.records)
        setTotal(response.data.data.length)
    }

    useEffect(() => {
        loadData(filterData);
        return () => {
            console.log('unmount')
        }
    }, [current, size])
    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)
    }

    const sizeChange = function (page, pagesize) {
        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize);
        setCurrent(page)
    };
    const style = { padding: '15px' };
    const dateFormat = 'YYYY-MM-DD';
    const sendDateChange = function (dates, dateStrings) {
        let tmpdata = { ...filterData, start_date: dateStrings[0], end_date: dateStrings[1] };
        setFilterData(tmpdata);
        console.log(filterData, 'filterData 为什么不会及时获取到呢 但是数据显示没问题 https://blog.csdn.net/aminwangaa/article/details/107379851')
    };
    const changeOperType = function (e) {
        console.log(e);
        let tmpdata = {...filterData,  oper_type: e.target.value};
        setFilterData(tmpdata)
    };
    const childRef = useRef()
    function getFilter() {
        // 也可以这样写https://segmentfault.com/q/1010000019538336
        // setFilterData(()=> {
        //     const nextParam = childRef.current.filter
        //     loadData(nextParam)
        //     return nextParam
        // })
        console.log(childRef.current.filter)
        setFilterData(childRef.current.filter)
        loadData(childRef.current.filter,1)
    }
    const disabledDate = (current) => {
        return current && current < moment('2020-08-01', "YYYYMMDD");
    }

    function changeParentFilter() {
        setFilterData(childRef.current.filter)
    }

    async function exportData() {
        const response = await reqProductOutExport(filterData);
        
        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.message);
                return;
            }
            window.location.href = response.data.data.url
        }else {
            message.error(response.data.message);
            return;
        }
    }
    const onFilter = function () {
        console.log(filterData);
        loadData(filterData)
    }
    const onExport = function () {
        exportData()
    };
    const content = (
        <div>
          <p>查询时间范围内，销售出库单或者其他出库单的产品出库数量汇总统计(不包含内部过账出库)</p>
        </div>
      );
    return (

        <div>
            <Row>
                <Col span={24}>
                    <div style={style}>
                        出库日期：<RangePicker onChange={sendDateChange}
                                          defaultValue={[moment(filterData.start_date, dateFormat), moment(filterData.end_date, dateFormat)]}
                                          disabledDate={disabledDate}/>

                            <Popover content={content}><QuestionCircleOutlined style={{ fontSize: '18px',  padding:'20px' }}/></Popover>

                    </div>

                </Col>
            </Row>
            <Row>
                <Col span={7}>
                    <div style={style}>
                        单据类型：<Radio.Group onChange={changeOperType} value={filterData.oper_type}>
                            <Radio value={''}>汇总</Radio>
                            <Radio value={'sale_out'}>销售出库</Radio>
                            <Radio value={'other_out'}>其他出库</Radio>
                        </Radio.Group>

                    </div>
                </Col>
                <Col span={12}>
                    <div style={style}>
                        <Button type="primary" style={{marginRight: 20, marginLeft: 20}} onClick={onFilter}>查询</Button>
                        <Button type="primary" onClick={onExport}>导出</Button>
                    </div>
                </Col>
            </Row>
            <Card>
                <Table rowKey={(record, index) => record.tmpkey} bordered dataSource={data} columns={columns} loading={loading} pagination={false} scroll={{ x: 1000 }} />
                <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div>

            </Card>
        </div>
    )
}