import request from '../utils/requestUtil'

function filterParam(obj) {
    for (let i in obj) {
        if (obj[i]) {
            obj[i] = obj[i].toString().trim()
        }else {
            obj[i] = ''
        }
        
    }
    return obj
}
export function reqShopList() {
    return request.get('/shop')
}

export function reqList(obj){
    let param = filterParam(obj)
    return request.post('/service/product',param)
}

export function reqExport(obj){
    let param = filterParam(obj)
    return request.post('/service/product/download',param)
}

export function reqExportDetail(obj) {
    let param = filterParam(obj)
    return request.post('/service/product/detail/download',param)
}