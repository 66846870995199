import React from 'react'
import { Form, Input, Button, message } from 'antd';
import './login.less'

import { reqLogin} from '../../api/login'
import { useHistory } from 'react-router-dom';


export default function () {
    const history = useHistory();
    if (localStorage.getItem('user')) {
        history.push('/')
    }
    const onFinish =  async values => {
        console.log('Received values of form: ', values);
        const response = await reqLogin(values.username,values.password)
        console.log(response)
        if (response.data.code === 0) {
            localStorage.setItem('user',JSON.stringify(response.data.data))
            localStorage.setItem('Authorization',response.data.data.access_token)
            history.push('/')
        }else {
            message.error(response.data.msg)
        }
    }
    return (
        <div className="login">
            <div className="main">
                <div className="accountWrap">
                    <h2>用户登录</h2>
                    <div className="mainForm">
                        <Form
                            className="login-form"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="username"
                                rules={[
                                    { required: true, message: '请输入用户名' },
                                    { min: 4, message: '用户名至少4位' },
                                    { max: 12, message: '用户名至多12位' },
                                    { pattern: /^[a-zA-Z0-9_]+$/, message: '用户名必须是英文字母下划线组合' }
                                ]}
                            >
                                <Input size="large" placeholder="用户名" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: '请输入密码' },
                                    { min: 4, message: '密码至少4位' },
                                    { max: 20, message: '密码至多20位' },
                                    { pattern: /^[a-zA-Z0-9_.]+$/, message: '密码必须是英文字母下划线组合' }
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    type="password"
                                    placeholder="密码"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                                    登 录
                            </Button>

                            </Form.Item>
                        </Form>
                    </div>

                </div>
            </div>
        </div>
    );

}