// import React, { Component } from 'react'
// import { Button } from 'antd';
// export default class Admin extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             count: 0
//         }
//     }
//     render() {
//         return (
//             <div>
//                 <p>you clicked {this.state.count}</p>
//                 <Button type="primary" onClick={this.addCount.bind(this)}>click me</Button>
//             </div>
//         );
//     }
//     addCount() {
//         this.setState({count:this.state.count + 1})
//     }
// }
import React, { useState,useEffect } from 'react'


function sleep(time) {
    return new Promise((resolve)=> {
        resolve()
    },time)
}

async function getPerson() {
    await sleep(200)
    return ["a","b","c"]
}
function usePerson() {
    const [list,setList] = useState(null)
    
    useEffect(() => {
        async function request() {
            let list = await getPerson()
            setList(list)
        }
        request()
    }, [])

    return list
}


function Admin() {

    
    const list = usePerson()
    if (list === null) {
    return <div>loading...</div>
    }

    return (
        <div>
            {list.map((item,k)=> {
                return <li key={k}>{item}</li>
            })}
        </div>
    )
}
export default Admin;