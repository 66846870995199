import React, { useState, useEffect} from 'react'
import { Table, Pagination, DatePicker, Select, Row, Col } from 'antd'

import './index.less'
import { reqSaleTotal } from '../../api/saletotal.js'

const { Option } = Select;

const dataTypeList = [
    { name: '品牌', checked: true, },
    { name: '小微', checked: false, },
    { name: '部门', checked: false, },
    { name: '店铺', checked: false, },
    { name: '商品', checked: false, }
]


const columns1 = [
    {
        title: '品牌',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '目标',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '实际',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '完成率',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '同比',
        dataIndex: 'address',
        key: 'address',
    },
];
const columns2 = [
    {
        title: '小微',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '目标',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '实际',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '完成率',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '同比',
        dataIndex: 'address',
        key: 'address',
    },
];
const columns3 = [
    {
        title: '小微',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '所属部门',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '目标',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '实际',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '完成率',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '同比',
        dataIndex: 'address',
        key: 'address',
    },
];
const columns4 = [
    {
        title: '小微',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '所属部门',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '店铺名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '店铺代码',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '目标',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '实际',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '完成率',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '同比',
        dataIndex: 'address',
        key: 'address',
    },
];
const columns5 = [
    {
        title: '商品分类',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '商品代码',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '商品名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '规格编码',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '目标',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '实际',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '完成率',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '同比',
        dataIndex: 'address',
        key: 'address',
    },
];

const initialState = {
    rate: '',
    date: '',
    currentIndex: 0,
    brands: ''
}



export default function () {
    // const dataSource1 = [
    //     {
    //         key: '1',
    //         name: '胡彦斌',
    //         age: 32,
    //         address: '西湖区湖底公园1号',
    //     }
    // ];
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])
    const [data5, setData5] = useState([])
    const [filterData, setFilterData] = useState(initialState)

    const [total, setTotal] = useState(99)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [dataType, setDataType] = useState(dataTypeList)

    useEffect(() => {
        loadData(filterData)
        return () => {
            console.log('unmount')
        }
    }, [current, size])


    const rateChange = function (value) {
        setFilterData({ ...filterData, rate: value })
    }
    const sendDateChange = function (value) {
        setFilterData({ ...filterData, date: value })
    }
    const buttonClick = function (index) {
        console.log(index)
        let list = JSON.parse(JSON.stringify(dataType))
        list.forEach(item => {
            item.checked = false
        })
        list[index].checked = true
        let tmpObj = { ...filterData, currentIndex: index }
        setFilterData(tmpObj)
        setDataType(list)
        loadData(tmpObj)
    }
    async function loadData(data) {
        console.log(123)
        return;


        
        const response = await reqSaleTotal({ page_no: current, page_size: size, ...data });
        if (!response.data || !response.data.data) {
            console.log('error')
            return;
        }
        // if (response.data && response.data.data) {
            switch (data.currentIndex) {
                case 0:
                    setData1(response.data.data.rows)
                    setTotal(response.data.data.all_count)
                    break;
                case 1:
                    setData2(response.data.data.rows)
                    setTotal(response.data.data.all_count)
                    break;
                case 2:
                    setData3(response.data.data.rows)
                    setTotal(response.data.data.all_count)
                    break;
                case 3:
                    setData4(response.data.data.rows)
                    setTotal(response.data.data.all_count)
                    break;
                case 4:
                    setData5(response.data.data.rows)
                    setTotal(response.data.data.all_count)
                    break;
                default:
                    break;
            }

        // }


    }

    const onChange = function (page, pagesize) {
        setCurrent(page)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
    }
    const sizeChange = function (page, pagesize) {
        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)
    }

    const brandChange = function (value) {
        const v = value.join(',')
        // dispatch({
        //     brands: v
        // })
        setFilterData({ ...filterData, brands: v })
    }

    const style = { padding: '10px' }
    return (
        <div>
            <Row>
                <Col span={6}>
                    <div style={style}>
                        <Select defaultValue="0" placeholder="请选择" style={{ width: 180 }} onChange={rateChange}>
                            <Option value="0">同比</Option>
                            <Option value="1">环比</Option>
                        </Select>
                    </div>
                </Col>
                <Col span={18}>
                    <div style={style}>
                        日期：<DatePicker onChange={sendDateChange} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div style={style}>
                        {
                            dataType.map((currentValue, index) => {
                                return (
                                    <div className={currentValue.checked ? 'item item-active' : 'item'} key={index} onClick={() => buttonClick(index)}>
                                        {currentValue.name}
                                    </div>

                                    // currentValue.checked ?
                                    // <Button type="primary" key={index} onClick={()=>buttonClick(index)} style={{ marginRight: 20 }}>{currentValue.name}</Button>
                                    // :
                                    // <Button onClick={()=>buttonClick(index)} key={index} style={{ marginRight: 20 }}>{currentValue.name}</Button>
                                )
                            })
                        }


                    </div>
                </Col>
            </Row>
            <div style={style}>
                <div className={filterData.currentIndex == 0 ? 'cont cont-show' : 'cont'}>
                    <div style={style}>
                        品牌：
                    <Select defaultValue={[]} maxTagCount={1} mode="multiple" placeholder="请选择" style={{ width: 180 }} onChange={brandChange}>
                            <Option value="0">海尔</Option>
                            <Option value="1">nuby</Option>
                            <Option value="2">贝立安</Option>
                        </Select>
                    </div>
                    <Table dataSource={data1} columns={columns1} pagination={false} />
                </div>
                <div className={filterData.currentIndex == 1 ? 'cont cont-show' : 'cont'}>
                    <Table dataSource={data2} columns={columns2} pagination={false} />
                </div>
                <div className={filterData.currentIndex == 2 ? 'cont cont-show' : 'cont'}>
                    <Table dataSource={data3} columns={columns3} pagination={false} />
                </div>
                <div className={filterData.currentIndex == 3 ? 'cont cont-show' : 'cont'}>
                    <Table dataSource={data4} columns={columns4} pagination={false} />
                </div>
                <div className={filterData.currentIndex == 4 ? 'cont cont-show' : 'cont'}>
                    <Table dataSource={data5} columns={columns5} pagination={false} />
                </div>
            </div>
            <div style={style} className="pagenation">
                <Pagination
                    showQuickJumper
                    showSizeChanger
                    defaultCurrent={1}
                    showTotal={total => `总共 ${total} 条`}
                    total={total}
                    pageSize={size}
                    onShowSizeChange={sizeChange}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}