import React, { useState, useEffect, useReducer, useImperativeHandle, forwardRef } from 'react'
import { Radio, DatePicker, Select, Button, Input, Checkbox, Row, Col, message } from 'antd'
import { reqShopList, reqFirstCate, reqSecondCate } from '../../api/orderdetail'
import moment from 'moment'
const { Option } = Select;
const { RangePicker } = DatePicker;
function reducer(filter, args) {

    return { ...filter, ...args }
}

function useShopName() {
    // const pids = value
    const [shopname, setShopName] = useState([])
    async function loadShopName() {
        const response = await reqShopList()
        if (response.data.code == '-1') {
            return;
        }
        setShopName(response.data.data)
    }
    useEffect(() => {
        loadShopName()
        return () => {
            console.log('unmount')
        }
    }, [])
    return [shopname, loadShopName]
}

function useFirstCate() {

    const [firstcate, setFirstCate] = useState([])
    async function loadFirstCate() {
        const response = await reqFirstCate()

        if (response.data.data && response.data.code != '-1') {
            setFirstCate(response.data.data.records)
        } else {
            // message.error(response.data.message)
            console.log(response.data.message)
        }


    }
    useEffect(() => {
        loadFirstCate()
        return () => {
            console.log('unmount')
        }
    }, [])
    return [firstcate, loadFirstCate]
}
function useSecondCate() {

    const [secondcate, setSecondCate] = useState([])
    async function loadSecondCate(pid) {
        const response = await reqSecondCate({ first_categ: pid })
        if (response.data.data && response.data.code != '-1') {
            setSecondCate(response.data.data.records)
        } else {
            // message.error(response.data.message)
            console.log(response.data.message)
        }

    }
    useEffect(() => {

        return () => {
            console.log('unmount')
        }
    }, [])
    return [secondcate, loadSecondCate]
}

const OrderFilter = (props, ref) => {
    const [filter, dispatch] = useReducer(reducer, props.data)
    const [shopname, loadShopName] = useShopName([])
    const [firstcate] = useFirstCate()
    const [secondcate, loadSecondCate] = useSecondCate()
    const payDateChange = function (dates, dateStrings) {
        dispatch({
            start_pay_time: dateStrings[0],
            end_pay_time: dateStrings[1]
        })
    }
    const sendDateChange = function (dates, dateStrings) {
        dispatch({
            start_send_time: dateStrings[0],
            end_send_time: dateStrings[1]
        })
    }
    const shopChange = function (value) {
        const v = value.join(',')
        // const v = JSON.stringify(value)
        dispatch({
            customer_numbers: v
        })
    }
    const orderCodeChange = function (e) {
        console.log(e.target.value)
        dispatch({
            order_code: e.target.value
        })
    }
    const plantCodeChange = function (e) {
        dispatch({
            plant_code: e.target.value
        })
    }
    const statusChange = function (value) {
        dispatch({
            send_goods_status: value
        })
    }
    const expressCodeChange = function (value) {
        dispatch({
            express_code: value
        })
    }
    const proFirstChange = function (value) {

        loadSecondCate(value)
        dispatch({
            f_cate_id: value
        })
    }
    const proSecondChange = function (value) {

        dispatch({
            s_cate_id: value
        })
    }
    const proCodeChange = function (e) {
        dispatch({
            pro_code: e.target.value
        })
    }
    const sizeCodeChange = function (e) {
        dispatch({
            size_code: e.target.value
        })
    }
    const vipNameChange = function (e) {
        dispatch({
            vip_name: e.target.value
        })
    }
    useImperativeHandle(
        ref,
        () => {
            return {
                filter: filter
            }
        }
    )
    const style = { padding: '10px' }
    const dateFormat = 'YYYY-MM-DD';
    const disabledDate = (current) => {
        return current && current < moment('2020-08-01', "YYYYMMDD");
    }
    return (
        <div>
            <Row>
                {/* <Col span={6}>
                    <div style={style}>
                        付款时间：<RangePicker onChange={payDateChange} picker={'day'} />
                    </div>
                </Col> */}
                <Col span={18}>
                    <div style={style}>
                        发货时间：<RangePicker onChange={sendDateChange} disabledDate={disabledDate} defaultValue={[moment(filter.start_send_time, dateFormat), moment(filter.end_send_time, dateFormat)]}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={10}>
                    <div style={style}>
                        店铺名称：
                        <Select defaultValue={[]} maxTagCount={1} mode="multiple" placeholder="请选择" style={{ width: 260 }} onChange={shopChange}
                            filterOption={(input, option) => {
                                
                                // console.log(input,option)
                                // return option && option.props && option.props.children && option.props.children.indexOf(input) >= 0
                                return option && option.children.indexOf(input) >= 0
                            }}>
                            {
                                shopname.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.shop_code}>{currentValue.shop_name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Col>
                <Col span={7}>
                    <div style={style}>
                        订单编号：<Input placeholder="请输入订单编号" onChange={orderCodeChange} style={{ width: 140 }} />
                    </div>
                </Col>
                <Col span={7}>
                    <div style={style}>
                        平台单号：<Input placeholder="请输入平台单号" onChange={plantCodeChange} style={{ width: 140 }} />
                    </div>
                </Col>
                {/* <Col span={6}>
                    <div style={style}>
                        发货状态：
                        <Select defaultValue="0" style={{ width: 140 }} onChange={statusChange}>
                            <Option value="0">全部</Option>
                            <Option value="1">未发货</Option>
                            <Option value="2">已发货</Option>
                        </Select>

                    </div>
                </Col> */}
            </Row>
            <Row>
                {/* <Col span={6}>
                    <div style={style}>
                        物流单号：<Input placeholder="请输入物流单号" onChange={expressCodeChange} style={{ width: 140 }} />
                    </div>
                </Col> */}
                <Col span={10}>
                    <div style={style}>

                        {/* <Select defaultValue="0" style={{ width: 140, marginRight: 20 }} onChange={proFirstChange}>
                            <Option value="0">Jack</Option>
                            <Option value="1">Lucy</Option>
                            <Option value="2">Jim</Option>
                        </Select>
                        <Select defaultValue="0" style={{ width: 140 }} onChange={proSecondChange}>
                            <Option value="0">Jack</Option>
                            <Option value="1">Lucy</Option>
                            <Option value="2">Jim</Option>
                        </Select> */}

                        商品分类：
                        <Select defaultValue="" placeholder="请选择" style={{ width: 120, marginRight: 20 }} onChange={proFirstChange}>
                            <Option value="">请选择</Option>
                            {
                                firstcate.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>
                        <Select defaultValue="" placeholder="请选择" style={{ width: 120, marginRight: 20 }} onChange={proSecondChange}>
                            <Option value="">请选择</Option>
                            {
                                secondcate.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>

                    </div>
                </Col>
                <Col span={7}>
                    <div style={style}>
                        物料编码：<Input placeholder="请输入编码" onChange={proCodeChange} style={{ width: 140 }} />
                    </div>
                </Col>
                <Col span={7}>
                    <div style={style}>
                        规格型号：<Input placeholder="请输入型号" onChange={sizeCodeChange} style={{ width: 140 }} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={10}>
                    <div style={style}>
                        会员名称：<Input placeholder="请输入会员名称" onChange={vipNameChange} style={{ width: 140 }} />
                    </div>
                </Col>
                <Col span={7}>
                    <div style={style}>
                        <Button type="primary" style={{ marginRight: 20 }} onClick={props.onFilter}>查询</Button>
                        <Button type="primary" style={{ marginRight: 20 }} onClick={props.onExport}>导出</Button>
                        <Button type="primary" onClick={() => { window.location.reload() }}>重置</Button>
                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default forwardRef(OrderFilter)