import React, { useEffect, useCallback, useState } from 'react'
import './index.less'

import { Modal, Select, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { reqCompany } from '../../api/home'
const { Option } = Select;


export default function () {
    let history = useHistory()
    const logOut = useCallback(() => {
        Modal.confirm({
            title: '确定退出登陆吗?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                console.log('OK');
                // localStorage.clear()
                localStorage.removeItem('Authorization')
                // history.replace('/login')
                window.location.href = process.env.REACT_APP_AUTH_PATH
            },
            onCancel() {
                console.log('Cancel');
            },
            okText: '确认',
            cancelText: '取消'
        });
    }, [])
    const [companyData, setCompanyData] = useState([])

    const [optionVal, setOptionVal] = useState([])
    function companyChange(v, item) {
        console.log(v, item)
        localStorage.setItem('company', v.join(','))
        let ids = [];
        item.forEach((current, value) => {
            ids.push(current.key)
        })
        localStorage.setItem('company_id', ids.join(','))
        let res = JSON.parse(localStorage.getItem('list'))
        res.forEach((current, index) => {
            current.is_default = false
            if (v.indexOf(current.finance_code) >= 0) {
                current.is_default = true
            }
        })
        localStorage.setItem('list', JSON.stringify(res))
        setOptionVal(v)
    }
    function changeConfig() {
        window.location.reload()
    }
    async function loadCompany() {
        if (localStorage.getItem('list')) {
            setCompanyData(JSON.parse(localStorage.getItem('list')))

            setOptionVal(localStorage.getItem('company').split(','))
            return;
        }
        // const response = await reqCompany()
        // console.log(response)
        // if (response.data.code == -1) {
        //     return;
        // }
        localStorage.setItem('list', JSON.stringify([
            { "finance_code": "101", "id": 3, "is_default": true, "name": "101上海挚语国际贸易有限公司" },
            { "finance_code": "102", "id": 4, "is_default": true, "name": "102深圳擎海智健科技有限公司" },
            { "finance_code": "201", "id": 5, "is_default": true, "name": "201上海挚语科技有限公司" },
            { "finance_code": "202", "id": 6, "is_default": true, "name": "202上海擎海母婴用品有限公司" },
            { "finance_code": "300", "id": 7, "is_default": true, "name": "300青岛绿洲创智科技信息有限公司" },
            { "finance_code": "301", "id": 8, "is_default": true, "name": "301青岛贝立安科技有限公司" },
            { "finance_code": "600", "id": 9, "is_default": true, "name": "600香港绿洲智健智能科技有限公司" },
            { "finance_code": "601", "id": 10, "is_default": true, "name": "601香港绿洲智健科技有限公司" },
            { "finance_code": "802", "id": 11, "is_default": true, "name": "802上海其先电子商务有限公司" },
            { "finance_code": "803", "id": 12, "is_default": true, "name": "803上海凰铭科技有限公司" },
            { "finance_code": "100", "id": 13, "is_default": false, "name": "100深圳绿洲智健科技有限公司" },
            { "finance_code": "200", "id": 14, "is_default": false, "name": "200上海绿洲智健智能科技有限公司" },
            { "finance_code": "203", "id": 16, "is_default": false, "name": "203福州擎海科技有限公司" }
        ]))
        // setCompanyData(response.data.data.records)
        let res = JSON.parse(localStorage.getItem('list'))
        setCompanyData(res)
        let arr = []
        let arr_id = []
        res.map((current, index) => {

            if (current.is_default) {
                arr.push(current.finance_code)
                arr_id.push(current.id)
            }

        })
        localStorage.setItem('company', arr.join(','))
        localStorage.setItem('company_id', arr_id.join(','))
        setOptionVal(arr)

    }
    useEffect(() => {
        loadCompany()
        return () => {
        }
    }, [])
    return (
        <div style={{ height: 99, background: '#fff' }} className="header">
            <div className="header-top">
                欢迎 <a href="#!" onClick={() => logOut()}>退出登录</a>
            </div>
            <div className="header-bottom">
                <div>
                    <div style={{ float: 'right', paddingTop: 15, paddingRight: 40 }}>
                        <Select value={optionVal} maxTagCount={1} mode="multiple" placeholder="请选择" style={{ width: 320 }} onChange={companyChange}>
                            {
                                companyData.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.finance_code}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>
                        <Button type="primary" style={{ marginLeft: 20 }} onClick={changeConfig}>应用</Button>
                    </div>

                </div>
            </div>
        </div>
    )
}