import React, { useReducer, useRef } from 'react'

export default function ShoppingList() {
    const inputRef = useRef();
    const [items, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'add':
                return [...state,
                {
                    id: state.length,
                    name: action.name
                }]
        }
    }, [])

    function handleSubmit(event) {
        event.preventDefault();
        dispatch({
            type: 'add',
            name: inputRef.current.value
        });
        inputRef.current.value = '';
    }

    return (
        <>
            <form onSubmit={handleSubmit}>

                <input ref={inputRef} />
            </form>
            <ul>
                {items.map(item => (
                    <li key={item.id}>{item.name}</li>
                ))}
            </ul>
        </>
    )
}