import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Card, Table, Pagination, message } from 'antd'

import SaleReturnFilter from '../../components/salereturnfilter'
import { reqReturn, reqExportReturn } from '../../api/return'

import './index.less'

const columns = [

    {
        title: '退货日期',
        dataIndex: 'return_date',
    },
    {
        title: '退货单编号',
        dataIndex: 'name',
    },
    {
        title: '退货类型',
        dataIndex: 'return_type',
    },
    {
        title: '客户名称',
        dataIndex: 'partner_name',
    },
    {
        title: '客户编码',
        dataIndex: 'sale_finance_code',
    },
    {
        title: '品牌',
        dataIndex: 'brand',
    },
    {
        title: '商品分类',
        dataIndex: 'category_name',
    },

    {
        title: '商品名称',
        dataIndex: 'product_name',
    },
    {
        title: '商品代码',
        dataIndex: 'default_code',
    },
    {
        title: '规格型号',
        dataIndex: 'specification_code',
    },
    {
        title: '退回数量',
        dataIndex: 'return_qty',
    },
    {
        title: '退回含税价',
        dataIndex: 'price_unit',
    },
    {
        title: '退回金额',
        dataIndex: 'price_total',
    },
    {
        title: '物流公司',
        dataIndex: 'logistics_company',
    },

    {
        title: '物流单号',
        dataIndex: 'logistics_order',
    }

];

const date = new Date()
let y = date.getFullYear()
let m = Number(date.getMonth()) + 1 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
let d = Number(date.getDate()) >= 10 ? Number(date.getDate()) : '0' + Number(date.getDate())
let today = y + '-' + m + '-' + d
let first = y + '-' + m + '-' + '01'

const initialState = {
    start_date: first,
    end_date: today,
    product_brand: '',
    first_categ: '',
    second_categ: '',
    product_code: '',
    product_specification: ''
}

export default function () {

    const [data, setData] = useState([])

    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading, setLoading] = useState(false)
    async function exportData() {
        const response = await reqExportReturn(filterData);

        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.message)
                return;
            }
            window.location.href = response.data.data.url
        }else {
            message.error(response.data.message)
            return;
        }
    }
    async function loadData(filterData, state = 0) {
        setLoading(true)
        setData([])
        setTotal(0)
        let response;
        if (state == 1) {
            response = await reqReturn({ page: 1, size: size, ...filterData });
        } else {
            response = await reqReturn({ page: current, size: size, ...filterData });
        }

        setLoading(false)
        if (response.data.code == '-1') {
            // message.error(response.data.msg)
            return;
        }
        setData(response.data.data.records)
        setTotal(response.data.data.total)

    }

    useEffect(() => {
        loadData(filterData)
        return () => {
            console.log('unmount')
        }
    }, [current, size])
    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)
    }
    const sizeChange = function (page, pagesize) {
        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)
    }

    const childRef = useRef()
    function getFilter() {
        // 也可以这样写https://segmentfault.com/q/1010000019538336
        // setFilterData(()=> {
        //     const nextParam = childRef.current.filter
        //     loadData(nextParam)
        //     return nextParam
        // })
        console.log(childRef.current.filter)
        setFilterData(childRef.current.filter)
        loadData(childRef.current.filter, 1)
    }

    function changeParentFilter() {
        setFilterData(childRef.current.filter)
    }

    return (
        
        <div>
            <div style={{padding:'10px'}}>取ERP的退货单</div>
            <SaleReturnFilter ref={childRef} onFilter={getFilter} onExport={exportData} onChangeFilter={changeParentFilter} data={filterData}></SaleReturnFilter>

            <Card>
                <Table rowKey={(record, index) => record.id} bordered dataSource={data} columns={columns} loading={loading} pagination={false} scroll={{ x: 2400 }} />
                <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div>

            </Card>
        </div>
    )
}