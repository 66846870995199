import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import LeftNav from '../../components/left-nav'
import Header from '../../components/header'


import Home from '../home/home'
import Saleorder from '../saleorder/saleorder'
import Saletotal from '../saletotal/saletotal'
import Orderdetail from '../orderdetail/orderdetail'
import Odoo from '../odoo/odoo'
import Role from '../role/role'
import User from '../user/user'
import Outtotalmoney from '../charts/outtotalmoney'
import Goodssalemoney from '../charts/goodssalemoney'
import Goodssaleamount from '../charts/goodssaleamount'
import Customsalemoney from '../charts/customsalemoney'
import Salereturn from '../return/salereturn'
import Prototal from '../prototal/prototal'
import Productout from '../productout/productout'



const { Sider, Content } = Layout;

const GetUrlParam = (paraName) => {
    var url = document.location.toString();
    var arrObj = url.split("?");

    if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split("=");

            if (arr != null && arr[0] == paraName) {
                return arr[1];
            }
        }
        return "";
    }
    else {
        return "";
    }
}

function Admin() {

    if (GetUrlParam('token') == "" && !localStorage.getItem('Authorization')) {
        window.location.href = process.env.REACT_APP_AUTH_PATH
        return;
    }

    if (GetUrlParam('token')) {
        localStorage.setItem('Authorization', GetUrlParam('token'));
    }


    const auth = localStorage.getItem('Authorization')
    if (!auth) {
        return <Redirect to='/login' />
    }

    return (
        <Layout style={{ height: '100%' }}>

            <LeftNav></LeftNav>

            <div style={{marginLeft:215,background:'#f0f2f5'}}>
                <Header></Header>
                <div style={{ padding: 20 }}>
                    <div style={{ backgroundColor: '#fff' }}>
                        <Switch>
                            <Route path='/home' component={Home}></Route>
                            <Route path='/saleorder' component={Saleorder}></Route>
                            <Route path='/saletotal' component={Saletotal}></Route>
                            <Route path='/orderdetail' component={Orderdetail}></Route>
                            <Route path='/odoo' component={Odoo}></Route>
                            <Route path='/role' component={Role}></Route>
                            <Route path='/user' component={User}></Route>
                            <Route path='/outtotalmoney' component={Outtotalmoney}></Route>
                            <Route path='/goodssalemoney' component={Goodssalemoney}></Route>
                            <Route path='/goodssaleamount' component={Goodssaleamount}></Route>
                            <Route path='/customsalemoney' component={Customsalemoney}></Route>
                            <Route path='/salereturn' component={Salereturn}></Route>
                            <Route path='/prototal' component={Prototal}></Route>
                            <Route path='/productout' component={Productout}></Route>
                            {/* <Redirect to='/home'></Redirect> */}
                            <Redirect to='/home'></Redirect>
                        </Switch>
                    </div>
                </div>
            </div>

            {/* <Footer style={{textAlign:"center",color:"#666"}}>推荐使用谷歌浏览器，可以获得更佳页面体验</Footer> */}

        </Layout>
    )
}
export default Admin;