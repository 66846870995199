import request from '../utils/requestUtil'
function filterParam(obj) {
    for (let i in obj) {
        if (obj[i]) {
            obj[i] = obj[i].toString().trim()
        }else {
            obj[i] = ''
        }
        
    }
    return obj
}
export function reqSaleOrder(saleInfo) {
    let param = filterParam(saleInfo)
    return request.post('/trade/delivery',param)
}

export function reqEntity() {
    return request.get('/business/entity')
}

export function reqDepartment(pidInfo) {
    let param = filterParam(pidInfo)
    return request.get('/department',param)
}

export function reqFirstCate() {
    // return request.get('/first/category')
    return request.post('/product/category')
}

export function reqSecondCate(pidInfo) {
    let param = filterParam(pidInfo)
    // return request.get('/second/category',param)
    return request.post('/product/second/category',param)
}


export function reqExport(saleInfo) {
    let param = filterParam(saleInfo)
    return request.post('/trade/delivery/download',param)
}

export function reqBrand() {
    return request.post('/product/brand')
}