import React, { useState, useEffect, useReducer, useImperativeHandle, forwardRef } from 'react'
import { Radio, DatePicker, Select, Button, Input, Checkbox, Row, Col, message } from 'antd'
import { reqEntity, reqDepartment, reqFirstCate, reqSecondCate, reqBrand } from '../../api/saleorder'
import './index.less'
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Option } = Select;

function reducer(filter, args) {

    return { ...filter, ...args }
}

function useEntity() {
    const [entity, setEntity] = useState([])
    async function loadEntity() {
        const response = await reqEntity()
        if (!response.data.data) {
            return;
        }
        setEntity(response.data.data)
    }
    useEffect(() => {
        loadEntity()
        return () => {
            console.log('unmount')
        }
    }, [])
    return [entity, loadEntity]
}
function useDepartment() {
    // const pid = value
    const [department, setDepartment] = useState([])
    async function loadDepartment(pid) {
        const response = await reqDepartment({ xw_id: pid })
        if (!response.data.data) {
            return;
        }
        setDepartment(response.data.data)
    }
    useEffect(() => {
        // loadDepartment(pid)
        return () => {
            console.log('unmount')
        }
    }, [])
    return [department, loadDepartment]
}
function useBrands() {
    const [brandsList, setBrandsList] = useState([])
    async function loadBrands() {
        const response = await reqBrand()
        if (response.data.data && response.data.code != '-1') {
            setBrandsList(response.data.data.records)
        } else {
            message.error(response.data.message)
            console.log(response.data.message)
        }

    }
    useEffect(() => {
        loadBrands()
        return () => {
            console.log('unmount')
        }
    }, [])
    return [brandsList]
}

function useFirstCate() {

    const [firstcate, setFirstCate] = useState([])
    async function loadFirstCate() {
        const response = await reqFirstCate()

        if (response.data.data && response.data.code != '-1') {
            setFirstCate(response.data.data.records)
        } else {
            message.error(response.data.message)
            console.log(response.data.message)
        }


    }
    useEffect(() => {
        loadFirstCate()
        return () => {
            console.log('unmount')
        }
    }, [])
    return [firstcate, loadFirstCate]
}
function useSecondCate() {

    const [secondcate, setSecondCate] = useState([])
    async function loadSecondCate(pid) {
        const response = await reqSecondCate({ first_categ: pid })
        if (response.data.data && response.data.code != '-1') {
            setSecondCate(response.data.data.records)
        } else {
            // message.error(response.data.message)
            console.log(response.data.message)
        }

    }
    useEffect(() => {

        return () => {
            console.log('unmount')
        }
    }, [])
    return [secondcate, loadSecondCate]
}
let brand = []
let xiaowei = []
let customer_name = []
let principal_user = []
let customer_code = []
let product_code = []
let product_name = []
let product_category = []
let sku_code = []


let date = []


const Salefilter = (props, ref) => {

    const [entitys] = useEntity()
    const [department, loadDepartment] = useDepartment([])

    const [firstcate] = useFirstCate()
    const [secondcate, loadSecondCate] = useSecondCate()
    const [filter, dispatch] = useReducer(reducer, props.data)
    const [brandsList] = useBrands()



    const groupTitleArr = function () {
        return [...date, ...brand, ...xiaowei, ...customer_name, ...principal_user, ...customer_code, ...product_code, ...product_name, ...product_category, ...sku_code]
    }

    const dateChange = function (dates, dateStrings) {
        dispatch({
            start_date: dateStrings[0],
            end_date: dateStrings[1]
        })
    }
    const brandChange = function (value) {

        dispatch({
            product_brand: value
        })
    }
    const isIncloudChange = function (value) {
        dispatch({
            is_include_internal: value
        })
    }
    const orgFirstChange = function (value) {

        console.log(value)
        loadDepartment(value)
        dispatch({
            xw_id: value
        })
    }
    const orgSecondChange = function (value) {

        // loadShopName(v)
        dispatch({
            customer_number: value
        })
    }

    const proFirstChange = function (v) {



        loadSecondCate(v)
        dispatch({
            f_cate_id: v
        })
    }
    const proSecondChange = function (v) {

        dispatch({
            s_cate_id: v
        })
    }
    const proCodeChange = function (e) {
        console.log(e.target.value)
        dispatch({
            product_code: e.target.value
        })
    }
    const sizeCodeChange = function (e) {
        console.log(e.target.value)
        dispatch({
            sku_code: e.target.value
        })
    }
    const brandGroupChange = function (e) {
        console.log(e.target.checked)
        if (e.target.checked) {
            brand = [{
                title: '品牌',
                width: 120,
                dataIndex: 'brand_name',
            }]
        } else {
            brand = []
        }
        // props.onTitleArr()

        console.log(e.target)
        dispatch({
            group_by_brand: e.target.checked
        })

    }


    const xwGroupChange = function (e) {
        if (e.target.checked) {
            xiaowei = [{
                title: '小微',
                width: 120,
                dataIndex: 'xw_name',
            }]
        } else {
            xiaowei = []
        }

        dispatch({
            group_by_xw: e.target.checked
        })
    }

    const customerGroupChange = function (e) {
        if (e.target.checked) {
            customer_name = [{
                title: '客户名称',
                width: 120,
                dataIndex: 'partner_name',
            }]
            principal_user = [{
                title: '负责人',
                width: 120,
                dataIndex: 'principal_user',
            }]
            customer_code = [{
                title: '客户代码',
                width: 120,
                dataIndex: 'partner_code',
            }]
        } else {
            customer_name = []
            principal_user = []
            customer_code = []

        }
        dispatch({
            group_by_customer: e.target.checked
        })
    }
    const productCodeGroupChange = function (e) {
        if (e.target.checked) {
            product_code = [{
                title: '物料编码',
                width: 120,
                dataIndex: 'product_code',
            }]
            product_category = [{
                title: '商品分类',
                width: 120,
                dataIndex: 'product_category',
            }]
            product_name = [{
                title: '商品名称',
                width: 120,
                dataIndex: 'product_name',
            }]
        } else {
            product_code = []
            product_name = []
            product_category = []
        }
        dispatch({
            group_by_product_code: e.target.checked
        })
    }
    const sizeCodeGroupChange = function (e) {
        if (e.target.checked) {
            sku_code = [{
                title: '规格型号',
                width: 120,
                dataIndex: 'sku_code',
            }]
            product_category = [{
                title: '商品分类',
                width: 120,
                dataIndex: 'product_category',
            }]
            product_name = [{
                title: '商品名称',
                width: 120,
                dataIndex: 'product_name',
            }]
        } else {
            sku_code = []
            product_name = []
            product_category = []
        }
        dispatch({
            group_by_sku_code: e.target.checked
        })
    }
    const [ifshow, setIfshow] = useState(false)
    const unitChange = function (value) {

        if (value === "1") {
            //控制是否显示年月日
            setIfshow(false)

            //控制显示列表的日期字段
            date = []
        } else {
            //控制是否显示年月日
            setIfshow(true)

            //控制显示列表的日期字段
            date = [{
                title: '日期',
                width: 120,
                dataIndex: 'group_date',
            }]
        }

        dispatch({
            group_by_date: value
        })

    }

    const dateRadioChange = function (e) {

        console.log(e.target.value)
        dispatch({
            date_type: e.target.value
        })

    }

    useImperativeHandle(
        ref,
        () => {
            return {
                filter: filter,
                titleArr: groupTitleArr,
                dragArr: dragArrList
            }
        }
    )
    const style = { padding: '10px' }
    const dateFormat = 'YYYY-MM-DD';


    const disabledDate = (current) => {
        return current && current < moment('2020-08-01', "YYYYMMDD");
    }

    let dragArr = [
        {
            id: 0,
            name: '品牌',
            ename: 'brand_name',
            method: brandGroupChange
        },
        {
            id: 1,
            name: '小微',
            ename: 'xw_name',
            method: xwGroupChange
        },
        {
            id: 2,
            name: '客户',
            ename: 'partner_code',
            method: customerGroupChange
        },
        {
            id: 3,
            name: '物料编码',
            ename: 'product_code',
            method: productCodeGroupChange
        },
        {
            id: 4,
            name: '规格型号',
            ename: 'sku_code',
            method: sizeCodeGroupChange
        }
    ]
    const [dragArrList, setDragArrList] = useState(dragArr)
    const [activeItem, setActiveItem] = useState(null)
    function dragStart(ev, item) {

        setActiveItem(item)

    }
    function handleDrop(ev, item) {
        ev.preventDefault()

        if (activeItem != item) {

            let i = dragArrList.findIndex((current, index) => {
                return current.id == item.id
            })
            let j = dragArrList.findIndex((current, index) => {
                return current.id == activeItem.id
            })
            dragArrList[i] = activeItem
            dragArrList[j] = item

            // let newArr = JSON.parse(JSON.stringify(dragArrList))
            // console.log(newArr)
            setDragArrList(dragArrList)
            setActiveItem(null)
            // setDragArrList(dragArrList[activeItem.id], item)

        }
    }
    function dragOver(ev) {
        ev.preventDefault()
    }
    return (
        <div>
            <Row>
                <Col span={10}>
                    <div style={style}>
                        日期：<RangePicker onChange={dateChange} disabledDate={disabledDate} defaultValue={[moment(filter.start_date, dateFormat), moment(filter.end_date, dateFormat)]}/>
                    </div>
                </Col>
                <Col span={6}>
                    <div style={style}>
                        品牌：
                    <Select defaultValue="" allowClear placeholder="请选择" style={{ width: 160 }} onChange={brandChange}>

                            {
                                brandsList.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Col>
                <Col span={6}>
                    <div style={style}>
                        是否包含内部公司：
                    <Select defaultValue="0" placeholder="请选择" style={{ width: 100 }} onChange={isIncloudChange}>
                            <Option value="0">不包含</Option>
                            <Option value="1">包含</Option>
                        </Select>
                    </div>
                </Col>


            </Row>
            <Row>
                <Col span={18}>
                    <div style={style}>
                        组织架构：
                    <Select defaultValue={[]} maxTagCount={1} mode="multiple" placeholder="请选择" style={{ width: 160, marginRight: 20 }} onChange={orgFirstChange}
                            filterOption={(input, option) => {
                                // return option && option.props && option.props.children && option.props.children.indexOf(input) >= 0
                                return option && option.children.indexOf(input) >= 0
                            }}>

                            {
                                entitys.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }

                        </Select>
                        <Select defaultValue={[]} maxTagCount={1} mode="multiple" placeholder="请选择" style={{ width: 260, marginRight: 20 }} onChange={orgSecondChange}
                        filterOption={(input, option) => {
                            return option && option.props && option.props.children && option.props.children.indexOf(input) >= 0
                        }}>

                            {
                                department.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.customer_code}>{currentValue.customer_name}</Option>
                                    )
                                })
                            }

                        </Select>

                    </div>
                </Col>

            </Row>
            <Row>
                <Col span={10}>
                    <div style={style}>
                        商品分类：
                        <Select defaultValue="" placeholder="请选择" style={{ width: 120, marginRight: 20 }} onChange={proFirstChange}>
                            <Option value="">请选择</Option>
                            {
                                firstcate.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>
                        <Select defaultValue="" placeholder="请选择" style={{ width: 120, marginRight: 20 }} onChange={proSecondChange}>
                            <Option value="">请选择</Option>
                            {
                                secondcate.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>

                </Col>
                <Col span={6}>
                    <div style={style}>
                        物料编码：<Input placeholder="请输入编码" onChange={proCodeChange} style={{ width: 160 }} />
                    </div>
                </Col>
                <Col span={6}>
                    <div style={style}>
                        规格型号：<Input placeholder="请输入型号" onChange={sizeCodeChange} style={{ width: 160 }} />
                    </div>
                </Col>
            </Row>

            <div style={style}>

                分组：
                {/* <div draggable="true" id='1' onDrop={handleDrop} onDragStart={dragStart} className="orgbox bg1">
                    <Checkbox onChange={brandGroupChange}>品牌</Checkbox>
                </div>
                <div draggable="true" id="2" onDrop={handleDrop} onDragStart={dragStart} className="orgbox bg1">
                    <Checkbox onChange={xwGroupChange}>小微</Checkbox>
                </div>

                <div draggable="true" id="3" onDrop={handleDrop} onDragStart={dragStart} className="orgbox bg2">
                    <Checkbox onChange={customerGroupChange}>客户</Checkbox>
                </div>
                <div draggable="true" id="4" onDrop={handleDrop} onDragStart={dragStart} className="orgbox bg2">
                    <Checkbox onChange={productCodeGroupChange}>物料编码</Checkbox>
                </div>
                <div draggable="true" id="5" onDrop={handleDrop} onDragStart={dragStart} className="orgbox bg3">
                    <Checkbox onChange={sizeCodeGroupChange}>规格编码</Checkbox>
                </div> */}

                {
                    dragArrList.map(function (currentValue) {
                        return (
                            <div draggable="true" item={currentValue} id={currentValue.id} onDragOver={dragOver} key={currentValue.id} onDrop={(ev) => handleDrop(ev, currentValue)} onDragStart={(ev) => dragStart(ev, currentValue)} className="orgbox bg1">

                                <Checkbox onChange={currentValue.method}>{currentValue.name}</Checkbox>
                            </div>
                        )

                    })
                }

                <Select defaultValue="1" style={{ width: 160, marginRight: 20 }} onChange={unitChange}>
                    <Option value="0">按时间单位显示</Option>
                    <Option value="1">日期选择汇总数</Option>
                </Select>
                {

                    ifshow
                        // filter.unit === "0"
                        ?
                        <Radio.Group onChange={dateRadioChange} value={filter.date_type}>
                            <Radio value={'D'}>日</Radio>
                            <Radio value={'M'}>月</Radio>
                            <Radio value={'Y'}>年</Radio>
                        </Radio.Group>
                        :
                        ''

                }

                <Button type="primary" style={{ marginRight: 20 }} onClick={props.onFilter}>查询</Button>
                <Button type="primary" style={{ marginRight: 20 }} onClick={props.onExport}>导出</Button>
                <Button type="primary" onClick={() => { window.location.reload() }}>重置</Button>
            </div>


        </div>
    )
}

export default forwardRef(Salefilter)




