import React, { useEffect, useState } from 'react'
import { Chart, Line, Point, Tooltip, Interval, Coordinate, Axis } from 'bizcharts';
import './index.less'
import { reqHome, reqLianqun,reqXiaowei, reqSquare, reqCompany } from '../../api/home'
import { mul } from '../../utils/floorMath'
import { DatePicker, Select,Button } from 'antd';
import moment from 'moment';
import { getWeekStartDate, getWeekEndDate, getMonthStartDate, getMonthEndDate, getQuarterEndDate, getQuarterStartDate, getToday, getYearStartDate, getYearEndDate } from '../../utils/date'
const { Option } = Select;

const { RangePicker } = DatePicker;
const data3 = [
    { item: '场景生态小微', count: 40, percent: 0.4 },
    { item: '触点场景小微', count: 21, percent: 0.21 },
    { item: '母婴电商', count: 17, percent: 0.17 },
    { item: '家电定制小微', count: 13, percent: 0.13 },
    { item: '大客户小微', count: 9, percent: 0.09 },
];

let dateList = [
    { item: '今天', value: '1', active: false },
    { item: '本周', value: '2', active: false },
    { item: '本月', value: '3', active: true },
    { item: '本季度', value: '4', active: false },
    { item: '全年', value: '5', active: false }
]

const cols = {
    amount: {
        formatter: val => {
            val = val + '万元';
            return val;
        },
    },
};

const initialState = {
    start_date: getMonthStartDate(),
    end_date: getMonthEndDate()
}

export default function () {
    const [filterData, setFilterData] = useState(initialState)
    const [date, setDate] = useState(dateList)

    const [daydata, setDaydata] = useState([]);
    const [monthdata, setMonthdata] = useState([]);
    const [lastTime, setLastTime] = useState('')
    const [squareData, setSquareData] = useState([]);
    const [lianqunData, setLianqunData] = useState([]);
    const [xiaoweiData, setXiaoweiData] = useState([]);

    const [companyData, setCompanyData] = useState([])

    const [optionVal, setOptionVal] = useState([])

    const [brandTotal,setBrandTotal] = useState(0)
    const [lianqunTotal,setLianqunTotal] = useState(0)
    const [xiaoweiTotal,setXiaoweiTotal] = useState(0)
    async function loadData() {
        const response = await reqHome();
        console.log(response)
        if (!response.data.data) {
            return;
        }
        response.data.data.setDaydata[2].val = Math.floor(response.data.data.setDaydata[2].val * 100) / 100
        response.data.data.setMonthdata[2].val = Math.floor(response.data.data.setMonthdata[2].val * 100) / 100
        setDaydata(response.data.data.setDaydata)
        setMonthdata(response.data.data.setMonthdata)
        setLastTime(response.data.data.create_time)
    }
    async function loadCompany() {
        const response = await reqCompany()
        console.log(response)
        if (response.data.code == -1) {
            return;
        }
        setCompanyData(response.data.data.records)
        let arr = []
        let arr_id = []
        response.data.data.records.map((current, index) => {

            if (current.is_default) {
                arr.push(current.finance_code)
                arr_id.push(current.id)
            }

        })
        sessionStorage.setItem('company',arr.join(','))
        sessionStorage.setItem('company_id',arr_id.join(','))
        setOptionVal(arr)

    }
    function companyChange(v,item) {
        console.log(v,item)
        sessionStorage.setItem('company',v.join(','))
        let ids = [];
        item.forEach((current,value)=> {
            ids.push(current.key)
        })
        sessionStorage.setItem('company_id',ids.join(','))
        setOptionVal(v)
    }
    async function loadDataSquare() {


        const response = await reqSquare({ start_date: filterData.start_date, end_date: filterData.end_date });


        if (!response.data.data) {
            return;
        }
        if (response.data.data.length == 0) {
            return;
        }


        let res = response.data.data;
        let total = 0
        total = res.reduce((prev, item) => {
            // console.log(prev,'a')
            return prev + item.amount
        }, 0)
        console.log(total)
        res.forEach((currentValue, index) => {
            let per = (Math.round((currentValue.amount / total) * 10000) / 10000)

            let mulRes = mul(per, 100)

            Object.assign(currentValue, { percent: mulRes + '%' })
            currentValue.amount = Math.floor(currentValue.amount * 100) / 100

            // currentValue = {...currentValue,percent:currentValue.amount/total}
        })
        console.log(res)
        setSquareData(res)
        setBrandTotal(total.toFixed(2))
        console.log(squareData, 'setSquareData 为什么不会及时获取到呢 但是数据显示没问题 https://blog.csdn.net/aminwangaa/article/details/107379851')
    }
    async function loadDataLianqun() {


        const response = await reqLianqun({ start_date: filterData.start_date, end_date: filterData.end_date });


        if (!response.data.data) {
            return;
        }
        if (response.data.data.length == 0) {
            return;
        }

        let res = response.data.data;
        let total = 0
        total = res.reduce((prev, item) => {
            // console.log(prev,'a')
            return prev + item.amount
        }, 0)
        console.log(total)
        res.forEach((currentValue, index) => {
            let per = (Math.round((currentValue.amount / total) * 10000) / 10000)

            let mulRes = mul(per, 100)

            Object.assign(currentValue, { percent: mulRes + '%' })
            currentValue.amount = Math.floor(currentValue.amount * 100) / 100

        })
        setLianqunData(res)
        setLianqunTotal(total.toFixed(2))
    }

    async function loadDataXiaowei() {


        const response = await reqXiaowei({ start_date: filterData.start_date, end_date: filterData.end_date });


        if (!response.data.data) {
            return;
        }

        if (response.data.data.length == 0) {
            return;
        }
        let res = response.data.data;
        let total = 0
        
        total = res.reduce((prev, item) => {
            // console.log(prev,'a')
            return prev + item.amount
        }, 0)
        console.log(total)
        res.forEach((currentValue, index) => {
            let per = (Math.round((currentValue.amount / total) * 10000) / 10000)

            let mulRes = mul(per, 100)

            Object.assign(currentValue, { percent: mulRes + '%' })
            currentValue.amount = Math.floor(currentValue.amount * 100) / 100

        })
        setXiaoweiData(res)
        setXiaoweiTotal(total.toFixed(2))
    }
    function dateChange(dates, dateStrings) {
        if (dateStrings[0] == "" && dateStrings[1] == "") {
            return;
        }
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        setDate(list)
        setFilterData({ ...filterData, start_date: dateStrings[0], end_date: dateStrings[1] })

    }
    function getDateByType(dateType, index) {
        let list = JSON.parse(JSON.stringify(dateList))
        list.forEach((item) => {
            item.active = false
        })
        list[index].active = true   // 后面有useState 的set方法设置了之后会重新渲染
        setDate(list)

        let startTime = ''
        let endTime = ''
        switch (dateType) {
            case '1':
                startTime = getToday()
                endTime = getToday()
                break;
            case '2':
                startTime = getWeekStartDate()
                endTime = getWeekEndDate()
                break;
            case '3':
                startTime = getMonthStartDate()
                endTime = getMonthEndDate()
                break;
            case '4':
                startTime = getQuarterStartDate()
                endTime = getQuarterEndDate()
                break;
            case '5':
                startTime = getYearStartDate()
                endTime = getYearEndDate()
                break;
            default:
                break;
        }
        setFilterData({ ...filterData, start_date: startTime, end_date: endTime })
    }
    function changeConfig() {

    }
    useEffect(() => {
        loadDataSquare()
        loadDataLianqun()
        loadDataXiaowei()
        // loadCompany()
        return () => {

        }
    }, [filterData.start_date, filterData.end_date])

    useEffect(() => {
        console.log(getWeekStartDate(), getWeekEndDate(), getMonthStartDate(), getMonthEndDate(), getQuarterStartDate(), getQuarterEndDate(), getToday(), getYearStartDate(), getYearEndDate())
        loadData()

        // loadDataSquare()
        // loadDataLianqun()
        return () => {

        }
    }, [])

    const disabledDate = (current)=> {
        return current && current < moment('2020-08-01',"YYYYMMDD");
    }
    return (
        <div>
            {/* <div style={{ height: 40 }}>
                <div style={{ float: 'right',paddingTop: 20,paddingRight:40 }}>
                    <Select value={optionVal} maxTagCount={1} mode="multiple" placeholder="请选择" style={{ width: 320 }} onChange={companyChange}>
                        {
                            companyData.map((currentValue) => {
                                return (
                                    <Option key={currentValue.id} value={currentValue.finance_code}>{currentValue.name}</Option>
                                )
                            })
                        }
                    </Select>
                    <Button type="primary" style={{ marginLeft: 20 }} onClick={changeConfig}>应用</Button>
                </div>

            </div> */}
            <p style={{ paddingLeft: 40, paddingTop: 20, paddingRight: 40, textAlign: "right" }}>最后查询时间：{lastTime} 更新频率：1小时/次（查询所有公司，上面选择查看的公司不会对结果起作用）</p>
            <div style={{ paddingTop: 20, paddingBottom: 20 }} className="clearfix">
                <ul className="daylist">
                    {

                        daydata.map((currentValue) => {
                            let str = '';
                            if (currentValue.index == 0) {
                                str = '笔'
                            } else if (currentValue.index == 1) {
                                str = '件'
                            } else {
                                str = '万元'
                            }
                            return (

                                <li key={currentValue.index}>{currentValue.key} <br /> <b style={{ fontSize: 22 }}>{currentValue.val ? currentValue.val: 0}</b> <span>{str}</span></li>

                            )
                        })
                    }
                </ul>
                <ul className="monthlist">
                    {
                        monthdata.map((currentValue) => {
                            let str = '';
                            if (currentValue.index == 0) {
                                str = '笔'
                            } else if (currentValue.index == 1) {
                                str = '件'
                            } else {
                                str = '万元'
                            }
                            return (

                                <li key={currentValue.index}>{currentValue.key} <br /> <b style={{ fontSize: 22 }}>{currentValue.val}</b> <span>{str}</span></li>

                            )
                        })
                    }
                </ul>
            </div>
            <div className="clearfix">
                <h3 style={{ paddingLeft: 40, float: 'left' }}>收入占比 (点击底部按钮可以显示隐藏部分品牌小微链群)</h3>
                <div className="right-box" style={{ float: 'right', paddingRight: 40 }}>
                    {
                        date.map((currentValue, index) => {
                            return <span key={index} className={currentValue.active ? 'active' : ''} onClick={() => getDateByType(currentValue.value, index)}>{currentValue.item}</span>
                        })
                    }
                日期：<RangePicker disabledDate = {disabledDate} onChange={dateChange} />
                </div>
            </div>

            <div className="top1 clearfix">
                <p style={{ paddingLeft: 40, paddingTop: 20 }}>品牌</p>
                <div className="fl">
                    <Chart width={500} height={350} data={squareData} scale={cols} autoFit>
                        <Coordinate type="theta" radius={0.75} />
                        <Tooltip showTitle={false} />
                        <Axis visible={false} />
                        <Interval
                            position="amount"
                            adjust="stack"
                            color="brand"
                            style={{
                                lineWidth: 1,
                                stroke: '#fff',
                            }}
                            label={['amount', {
                                content: (data) => {
                                    return `${data.brand}: ${data.percent}`;
                                },
                            }]}
                        />
                    </Chart>
                </div>
                <div className="fr" style={{ paddingRight: 40 }}>
                    <ul>
                        <p><span className="w200">品牌</span><span className="w150">收入(万)</span><span className="w150">占比(%)</span></p>
                        {
                            squareData.map((currentValue) => {
                                return (
                                    <li key={currentValue.brand}> <span className="w200">{currentValue.brand}</span> <span className="w150">{currentValue.amount} 万元</span> <span className="w150">{currentValue.percent}</span></li>
                                )
                            })
                        }
                        <p><span className="w200">总计</span><span className="w150">{brandTotal} 万元</span><span className="w150"></span></p>
                    </ul>
                </div>
            </div>

            <div className="top2 clearfix">
                <p style={{ paddingLeft: 40, paddingTop: 20 }}>链群</p>
                <div className="fl">
                    <Chart width={500} height={350} data={lianqunData} scale={cols} autoFit>
                        <Coordinate type="theta" radius={0.75} />
                        <Tooltip showTitle={false} />
                        <Axis visible={false} />
                        <Interval
                            position="amount"
                            adjust="stack"
                            color="chain"
                            style={{
                                lineWidth: 1,
                                stroke: '#fff',
                            }}
                            label={['amount', {
                                content: (data) => {
                                    return `${data.chain}: ${data.percent}`;
                                },
                            }]}
                        />
                    </Chart>
                </div>
                <div className="fr" style={{ paddingRight: 40 }}>
                    <ul>
                        <p><span className="w200">品牌</span><span className="w150">收入(万)</span><span className="w150">占比(%)</span></p>
                        {
                            lianqunData.map((currentValue) => {
                                return (
                                    <li key={currentValue.chain}> <span className="w200">{currentValue.chain}</span> <span className="w150">{currentValue.amount} 万元</span> <span className="w150">{currentValue.percent}</span></li>
                                )
                            })
                        }
                        <p><span className="w200">总计</span><span className="w150">{lianqunTotal} 万元</span><span className="w150"></span></p>
                    </ul>
                </div>
            </div>

            <div className="top3 clearfix">
                <p style={{ paddingLeft: 40, paddingTop: 20 }}>小微</p>
                <div className="fl">
                    <Chart width={500} height={350} data={xiaoweiData} scale={cols} autoFit>
                        <Coordinate type="theta" radius={0.75} />
                        <Tooltip showTitle={false} />
                        <Axis visible={false} />
                        <Interval
                            position="amount"
                            adjust="stack"
                            color="xw_name"
                            style={{
                                lineWidth: 1,
                                stroke: '#fff',
                            }}
                            label={['amount', {
                                content: (data) => {
                                    return `${data.xw_name}: ${data.percent}`;
                                },
                            }]}
                        />
                    </Chart>
                </div>
                <div className="fr" style={{ paddingRight: 40 }}>
                    <ul>
                        <p><span className="w200">品牌</span><span className="w150">收入(万)</span><span className="w150">占比(%)</span></p>
                        {
                            xiaoweiData.map((currentValue) => {
                                return (
                                    <li key={currentValue.xw_name}> <span className="w200">{currentValue.xw_name}</span> <span className="w150">{currentValue.amount} 万元</span> <span className="w150">{currentValue.percent}</span></li>
                                )
                            })
                        }
                        <p><span className="w200">总计</span><span className="w150">{xiaoweiTotal} 万元</span><span className="w150"></span></p>
                    </ul>
                </div>
            </div>
        </div>
    )
}