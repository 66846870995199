import request from '../utils/requestUtil'
function filterParam(obj) {
    for (let i in obj) {
        if (obj[i]) {
            obj[i] = obj[i].toString().trim()
        }else {
            obj[i] = ''
        }
        
    }
    return obj
}
export function reqLogin(username,password) {
    return request.post('/user/login',{username:username,password:password})
}

export function reqAddUser(user) {
    let param = filterParam(user)
    return request.post('/user/add',param)
}