import React, { useState, useEffect, useRef, useCallback } from 'react'
import {Card, Table, Pagination, message, Popover} from 'antd'

import SaleOrderFilter from '../../components/saleorderfilter'
import { reqSaleOrder, reqExport } from '../../api/saleorder'

import './index.less'
import {QuestionCircleOutlined} from "@ant-design/icons";

const date = new Date()
let y = date.getFullYear()
let m = Number(date.getMonth()) + 1 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
let d = Number(date.getDate()) >= 10 ? Number(date.getDate()) : '0' + Number(date.getDate())
let today = y + '-' + m + '-' + d
let first = y + '-' + m + '-' + '01'


const initialState = {
    start_date: first,
    end_date: today,
    product_brand: '',
    is_include_internal: '0',
    xw_id: '',
    customer_number: '',
    f_cate_id: '',
    s_cate_id: '',
    product_code: '',
    sku_code: '',
    group_by_brand: false,
    group_by_xw: false,
    group_by_customer: false,
    group_by_product_code: false,
    group_by_sku_code: false,
    group_by_date: '1',
    date_type: 'M',
    order: ''
}

export default function () {
    const [groupTitleArr, setGroupTitleArr] = useState([])

    let columns = [
        ...groupTitleArr,
        {
            title: '发货数量',
            width: 120,
            dataIndex: 'qty',
            filterDropdown: (<div></div>),
            filterIcon: <div>
                <Popover content={"发货单上的已发货数量汇总"}><QuestionCircleOutlined /></Popover>
            </div>
        },
        {
            title: '非赠品发货数量',
            width: 140,
            dataIndex: 'product_qty',
            filterDropdown: (<div></div>),
            filterIcon: <div>
                <Popover content={"发货单上的非赠品且有单价的发货数量汇总"}><QuestionCircleOutlined /></Popover>
            </div>
        },
        {
            title: '折后金额',
            width: 120,
            dataIndex: 'amount',
            filterDropdown: (<div></div>),
            filterIcon: <div>
                <Popover content={"发货单上该产品的折后金额汇总"}><QuestionCircleOutlined /></Popover>
            </div>
        },
        {
            title: '出库单数量',
            width: 120,
            dataIndex: 'order_num',
            filterDropdown: (<div></div>),
            filterIcon: <div>
                <Popover content={"发货单数量，即，有几张发货单"}><QuestionCircleOutlined /></Popover>
            </div>
        },
        {
            title: '无单价产品数量（非赠品）',
            width: 210,
            dataIndex: 'no_price_qty',
            filterDropdown: (<div></div>),
            filterIcon: <div>
                <Popover content={"发货单上非赠品且无单价的发货产品数量汇总"}><QuestionCircleOutlined /></Popover>
            </div>
        },
        {
            title: '赠品数量',
            dataIndex: 'gift_qty',
            filterDropdown: (<div></div>),
            filterIcon: <div>
                <Popover content={"发货单上该产品为赠品的数量汇总"}><QuestionCircleOutlined /></Popover>
            </div>
        }
    ];
    const [data, setData] = useState([])

    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading, setLoading] = useState(false)




    async function loadData(filterData, state = 0) {
        setLoading(true)
        // , ...filter
        setData([])
        setTotal(0)
        let response;
        if (state == 1) {
            response = await reqSaleOrder({ page: 1, size: size, ...filterData });
        } else {
            response = await reqSaleOrder({ page: current, size: size, ...filterData });
        }

        setLoading(false)
        if (response.data.code == '-1') {
            return;
        }
        if (response && response.data && response.data.data) {
            if (response.data.data.length == 0) {
                return;
            }
            response.data.data.records.forEach((item, index) => {
                Object.assign(item, { tmpkey: index })
            })

        } else {
            // message.error(response.data.message)

            return;
        }
        setData(response.data.data.records)
        setTotal(response.data.data.length)
    }

    useEffect(() => {
        loadData(filterData)
        return () => {
            console.log('unmount')
        }
    }, [current, size])
    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)

    }
    const sizeChange = function (page, pagesize) {
        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)

    }

    const childRef = useRef()
    function getFilter() {
        // 也可以这样写https://segmentfault.com/q/1010000019538336
        // setFilterData(()=> {
        //     const nextParam = childRef.current.filter
        //     loadData(nextParam)
        //     return nextParam
        // })

        //头部标题动态设置
        console.log(childRef.current.filter)
        setFilterData(() => {
            const next = childRef.current.filter
            let resStr = setTitleArr()
            loadData({ ...next, order: resStr }, 1)
            return next;
        })
    }

    function changeParentFilter() {
        setFilterData(childRef.current.filter)
    }

    function setTitleArr() {
        var res = childRef.current.titleArr()
        var drag = childRef.current.dragArr
        let resStr = serilizeDrag(drag)

        console.log(resStr)
        console.log(res)
        let resArr = getSerilizeGroupTitleArr(resStr, res)
        let item = res.find((current,index)=> {
            return current.dataIndex == 'group_date'
        })
        if (item) {
            resArr = [item,...resArr]
        }
        

        setGroupTitleArr(resArr)
        return resStr
    }
    function getSerilizeGroupTitleArr(resStr, res) {
        let resArr = []
        let tmpArr = resStr.split(',')

        tmpArr.forEach((currentValue, index) => {
            // 判断返回的是否为null  如果是null 会报错的
            if (!getCurrent(currentValue)) {
                return;
            }
            if (getCurrent(currentValue) instanceof Array) {

                let arr = getCurrent(currentValue)
                resArr = resArr.concat(arr)
                console.log(resArr)
            } else {
                resArr.push(getCurrent(currentValue))
            }

        })
        // 去除重复
        let newArr = []
        for (var i = 0; i < resArr.length; i++) {
            var flag = true;
            for (var j = 0; j < newArr.length; j++) {
                if (resArr[i].dataIndex == newArr[j].dataIndex) {
                    flag = false;
                    break
                };
            };
            if (flag) {
                newArr.push(resArr[i]);
            };
        };
        return newArr;
    }
    function getCurrent(value) {
        let obj = null;
        switch (value) {
            case 'brand_name':
                obj = {
                    title: '品牌',
                    width: 120,
                    dataIndex: 'brand_name',
                }
                break;
            case 'xw_name':
                obj = {
                    title: '小微',
                    width: 120,
                    dataIndex: 'xw_name',
                }
                break;
            case 'partner_code':
                obj = [
                    {
                        title: '客户名称',
                        width: 200,
                        dataIndex: 'partner_name',
                    },
                    {
                        title: '销售部门',
                        width: 120,
                        dataIndex: 'department_name',
                    },
                    {
                        title: '负责人',
                        width: 120,
                        dataIndex: 'principal_user',
                    },
                    {
                        title: '客户代码',
                        width: 120,
                        dataIndex: 'partner_code',
                    }
                ]
                break;
            case 'product_code':
                obj = [
                    {
                        title: '物料编码',
                        width: 120,
                        dataIndex: 'product_code',
                    },
                    {
                        title: '商品分类',
                        width: 120,
                        dataIndex: 'product_category',
                    },
                    {
                        title: '商品名称',
                        width: 200,
                        dataIndex: 'product_name',
                    }
                ]
                break;
            case 'sku_code':
                obj = [
                    {
                        title: '规格型号',
                        width: 120,
                        dataIndex: 'sku_code',
                    },
                    {
                        title: '商品分类',
                        width: 120,
                        dataIndex: 'product_category',
                    },
                    {
                        title: '商品名称',
                        width: 200,
                        dataIndex: 'product_name',
                    }
                ]
                break;
            default:
                break;
        }
        return obj;
    }
    function serilizeDrag(drag) {
        ischecked('group_by_brand', 0, drag)
        ischecked('group_by_xw', 1, drag)
        ischecked('group_by_customer', 2, drag)
        ischecked('group_by_product_code', 3, drag)
        ischecked('group_by_sku_code', 4, drag)
        let arr = []
        drag.forEach((currentValue, index) => {
            if (currentValue.checked) {
                arr.push(currentValue.ename)
            }
        })

        let resStr = arr.join(',')
        return resStr;
    }
    function ischecked(str, num, drag) {
        if (childRef.current.filter[str]) {
            // filterDrag = drag

            let i = drag.findIndex((current, index) => {
                return current.id == num
            })
            drag[i].checked = true;
        } else {
            let i = drag.findIndex((current, index) => {
                return current.id == num
            })
            drag[i].checked = false;
        }
    }


    async function exportData() {
        const response = await reqExport(filterData);

        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.message)
                return;
            }
            window.location.href = response.data.data.url
        }else {
            message.error(response.data.message)
            return;
        }
    }
    const content = (
        <div>
            <p>
            取数逻辑: 查询时间范围内ERP里的发货单，实时查询汇总
            </p>
        </div>
      );

    return (

        <div>
            <div style={{padding:'10px', color: 'darkgreen'}}>报表说明：
                <Popover content={content}><QuestionCircleOutlined style={{ fontSize: '18px'}}/></Popover>
            </div>
            <SaleOrderFilter ref={childRef} onFilter={getFilter} onExport={exportData} onTitleArr={setTitleArr} onChangeFilter={changeParentFilter} data={filterData}></SaleOrderFilter>
            <Card>
                <Table rowKey={(record, index) => record.tmpkey} bordered dataSource={data} loading={loading} columns={columns} pagination={false} scroll={{ x: 1900 }} />
                <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div>

            </Card>
        </div>
    )
}