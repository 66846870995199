import React, { useState, useEffect, useReducer, useImperativeHandle, forwardRef } from 'react'
import { Radio, DatePicker, Select, Button, Input, Checkbox, Row, Col } from 'antd'

import { reqBrandList,reqFirstCate,reqSecondCate } from '../../api/return'
import moment from 'moment'
const { Option } = Select;
const { RangePicker } = DatePicker;
function reducer(filter, args) {

    return { ...filter, ...args }
}

function useBrand() {
    // const pids = value
    const [brand, setBrand] = useState([])
    async function loadBrand() {
        const response = await reqBrandList()
        if (!response.data.data.records) {
            return;
        }
        setBrand(response.data.data.records)
    }
    useEffect(() => {
        loadBrand()
        return () => {
            console.log('unmount')
        }
    }, [])
    return [brand, loadBrand]
}

function useFirstCate() {
    // const pids = value
    const [firstcate, setFirstCate] = useState([])
    async function loadFirstCate() {
        const response = await reqFirstCate()
        if (!response.data.data.records) {
            return;
        }
        setFirstCate(response.data.data.records)


    }
    useEffect(() => {
        loadFirstCate()
        return () => {
            console.log('unmount')
        }
    }, [])
    return [firstcate, loadFirstCate]
}
function useSecondCate() {
    // const pids = value
    const [secondcate, setSecondCate] = useState([])
    async function loadSecondCate(v) {
        const response = await reqSecondCate({ first_categ: v })
        if (!response.data.data.records) {
            return;
        }
        setSecondCate(response.data.data.records)

    }
    useEffect(() => {
        // loadSecondCate(pids)
        return () => {
            console.log('unmount')
        }
    }, [])
    return [secondcate, loadSecondCate]
}

const OrderFilter = (props, ref) => {
    const [filter, dispatch] = useReducer(reducer, props.data)
    const [brand, loadBrand] = useBrand([])
    const [firstcate] = useFirstCate()
    const [secondcate, loadSecondCate] = useSecondCate()
    const [secondVal, setSecondVal] = useState("请选择")

    const sendDateChange = function (dates, dateStrings) {
        dispatch({
            start_date: dateStrings[0],
            end_date: dateStrings[1]
        })
    }
    const brandChange = function (value) {
        dispatch({
            product_brand: value
        })
    }

    const proFirstChange = function (value) {
        loadSecondCate(value)
        dispatch({
            first_categ: value,
            second_categ: ''
        })
        setSecondVal("请选择")
    }
    const proSecondChange = function (value) {
        setSecondVal(value)
        dispatch({
            second_categ: value
        })
    }
    const proCodeChange = function (e) {
        dispatch({
            product_code: e.target.value
        })
    }
    const sizeCodeChange = function (e) {
        dispatch({
            product_specification: e.target.value
        })
    }

    useImperativeHandle(
        ref,
        () => {
            return {
                filter: filter
            }
        }
    )
    const style = { padding: '10px' }
    const dateFormat = 'YYYY-MM-DD';
    const disabledDate = (current)=> {
        return current && current < moment('2020-08-01',"YYYYMMDD");
    }
    return (
        <div>
            <Row>

                <Col span={18}>
                    <div style={style}>
                        退货日期：<RangePicker onChange={sendDateChange} disabledDate={disabledDate} defaultValue={[moment(filter.start_date, dateFormat), moment(filter.end_date, dateFormat)]}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={10}>
                    <div style={style}>
                        商品品牌：
                        <Select defaultValue="" allowClear placeholder="请选择" style={{ width: 260 }} onChange={brandChange}>
                            {
                                brand.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Col>


            </Row>
            <Row>

                <Col span={10}>
                    <div style={style}>

                        商品分类：
                        <Select defaultValue="" placeholder="请选择" style={{ width: 140, marginRight: 20 }} onChange={proFirstChange}>
                            <Option value="">请选择</Option>
                            {
                                firstcate.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>
                        <Select value={secondVal} placeholder="请选择" style={{ width: 140, marginRight: 20 }} onChange={proSecondChange}>
                        <Option value="">请选择</Option>
                            {
                                secondcate.map((currentValue) => {
                                    return (
                                        <Option key={currentValue.id} value={currentValue.id}>{currentValue.name}</Option>
                                    )
                                })
                            }
                        </Select>

                    </div>
                </Col>
                <Col span={7}>
                    <div style={style}>
                        商品代码：<Input placeholder="请输入代码" onChange={proCodeChange} style={{ width: 140 }} />
                    </div>
                </Col>
                <Col span={7}>
                    <div style={style}>
                        规格型号：<Input placeholder="请输入型号" onChange={sizeCodeChange} style={{ width: 140 }} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={7}>
                    <div style={style}>
                        <Button type="primary" style={{ marginRight: 20 }} onClick={props.onFilter}>查询</Button>
                        <Button type="primary" style={{ marginRight: 20 }} onClick={props.onExport}>导出</Button>
                        <Button type="primary" onClick={()=>{window.location.reload()}}>重置</Button>
                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default forwardRef(OrderFilter)