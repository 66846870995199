import React from 'react'
import './index.less'
import { Menu } from 'antd';
import * as Icon from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';

import menuList from '../../config/menuConfig'

const { SubMenu } = Menu;
const Icons = Icon
// const s = new Set()
let currentKey = '';
const hasAuth = function (item) {
    const authArr = ['/home','/products','/saleorder','/orderdetail','/odoo','/outtotalmoney','/goodssalemoney','/goodssaleamount','/customsalemoney','/salereturn','/prototal', '/productout']  //,'/saletotal'
    // const username = JSON.parse(localStorage.getItem('user')).username  这个系统没有超级管理员 白名单 所以暂时不需要了 || username === 'songjf'
    if (authArr.includes(item.key) || item.isPublic) {  // || username === 'songjf'
        return true
    }else if(item.children) {   // 如果authArr 中有二级的 则其所在一级也是显示的
        if (!!item.children.find((child)=> authArr.includes(child.key))) {  // find返回的是某一项 强制转成bool
            return true
        }
    }
}
const getMenuList = function (menuList) {
    return menuList.map(function (item, i) {
        let currentIcon = React.createElement(Icons[item.icon])
        if (hasAuth(item)) {    //判断进入的权限
            if (!item.children) {
                return (
                    <Menu.Item key={item.key} icon={currentIcon}>
                        <Link to={item.key}>{item.title}</Link>
                    </Menu.Item>
                )
            } else {
                // 以下 return之前代码 为了解决 页面刷新 子菜单关闭没展开 通过设置 defaultOpenKeys
                let location = useLocation()
                let path = location.pathname
                let openkey = item.children.find((currentValue) => {
                    return currentValue.key === path
                })
                if (openkey) {
                    currentKey = item.key
                }

                return (
                    <SubMenu key={item.key} title={item.title} icon={currentIcon}>
                        {
                            getMenuList(item.children)
                        }
                    </SubMenu>
                )
            }
        }

    })
}


// s.add(getMenuList)
// console.log(s.size)


export default function () {

    let location = useLocation()
    let path = location.pathname
    const menuNodes = getMenuList(menuList)    //这里调用一遍为了获取 currentkey

    return (
        <div className="left-nav">
            <div className="left-nav-header">
                <h1>BI管理系统</h1>
            </div>
            <div>
                <Menu
                    selectedKeys={[path]}
                    defaultOpenKeys={[currentKey]}
                    mode="inline"
                    theme="dark"
                >
                    {/* <Menu.Item key="/home" icon={<PieChartOutlined />}>
                    <Link to='/home'>首页</Link>
                </Menu.Item>

                <SubMenu key="sub1" icon={<MailOutlined />} title="商品">
                    <Menu.Item key="/category" icon={<AppstoreOutlined />}> <Link to='/category'>品类管理</Link> </Menu.Item>
                    <Menu.Item key="/product" icon={<MenuUnfoldOutlined />}> <Link to='/product'>商品管理</Link> </Menu.Item>
                </SubMenu> */}
                    {
                        menuNodes
                    }

                </Menu>
            </div>
        </div>
    )
}