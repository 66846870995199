import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Row, Col, Card, Table, Pagination, message, DatePicker, Button, Popover } from 'antd'
import moment from 'moment'
import { reqOdooList, reqOdooExport } from '../../api/odoo'
import './index.less'
import {
    QuestionCircleOutlined
} from '@ant-design/icons';

const c1 = (
    <div>
        <p>除已取消的所有状态的订单，该型号的汇总数量</p>
    </div>
);
const c2 = (
    <div>
        <p>除已取消的所有状态的订单，该型号的订单明细的金额</p>
    </div>
)
const c3 = (
    <div>
        <p>已发货的产品的数量</p>
    </div>
)
const c4 = (
    <div>
        <p>订单状态已审核通过，发货状态为未发货</p>
    </div>
)
const c5 = (
    <div>
        <p>订单状态包含草稿、审核中、财务审核中、驳回</p>
    </div>
)
const c6 = (
    <div>
        <p>发货后退回的产品的数量</p>
    </div>
)
const { RangePicker } = DatePicker;
const columns = [
    {
        title: '客户',
        dataIndex: 'partner_name'
    },
    {
        title: '销售员',
        dataIndex: 'salesman'
    },
    {
        title: '销售部门',
        dataIndex: 'dept_name',
    },
    {
        title: '产品编码',
        dataIndex: 'product_default_code',
    },
    {
        title: '规格型号',
        dataIndex: 'product_specification_model',
    },
    {
        title: '发货仓库',
        dataIndex: 'warehouse_name',
    },
    {
        title: '订单产品数量',
        dataIndex: 'product_qty',
        filterDropdown: (<div></div>),
        filterIcon: <div>
            <Popover content={c1}><QuestionCircleOutlined /></Popover>
        </div>
    },
    {
        title: '订单产品金额',
        dataIndex: 'amount',
        filterDropdown: true,
        filterIcon:<Popover content={c2}><QuestionCircleOutlined /></Popover>

    },
    {
        title: '已交货产品数量',
        dataIndex: 'qty_delivered',
        filterDropdown: (<div></div>),
        filterIcon: <div>
            <Popover content={c3}><QuestionCircleOutlined /></Popover>
        </div>
    },
    {
        title: '已交货产品金额',
        dataIndex: 'amount_delivered',
    },
    {
        title: '已退回产品数量',
        dataIndex: 'return_qty',
        filterDropdown: (<div></div>),
        filterIcon: <div>
            <Popover content={c6}><QuestionCircleOutlined /></Popover>
        </div>
    },
    {
        title: '已审未发货产品数量',
        dataIndex: 'product_qty_approved',
        filterDropdown: (<div></div>),
        filterIcon: <div>
            <Popover content={c4}><QuestionCircleOutlined /></Popover>
        </div>
    },
    {
        title: '已审未发货产品金额',
        dataIndex: 'amount_approved',

    },
    {
        title: '未审核产品数量',
        dataIndex: 'product_qty_unapprove',
        filterDropdown: (<div></div>),
        filterIcon: <div>
            <Popover content={c5}><QuestionCircleOutlined /></Popover>
        </div>
    },
    {
        title: '未审核产品金额',
        dataIndex: 'amount_unapprove',
    }
];

const date = new Date()
let y = date.getFullYear()
let m = Number(date.getMonth()) + 1 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
let d = Number(date.getDate()) >= 10 ? Number(date.getDate()) : '0' + Number(date.getDate())
let today = y + '-' + m + '-' + d
let first = y + '-' + m + '-' + '01'

const initialState = {
    start_date: first,
    end_date: today,
}
function Odoo() {
    const [data, setData] = useState([])

    const [filterData, setFilterData] = useState(initialState)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(10)
    const [loading, setLoading] = useState(false)
    async function loadData() {
        // let session_id = sessionStorage.getItem('odoo_token')
        setLoading(true)
        setData([])
        setTotal(0)
        const res = await reqOdooList({
            ...filterData,
            "offset": current,
            "limit": size
        })

        console.log(res)
        setLoading(false)
        if (res.data && res.data.data && res.data.data.records && res.data.data.code != '-1') {
            res.data.data.records.forEach((item, index) => {
                Object.assign(item, { tmpkey: index })
            })
        } else {
            console.log('error')
            message.error(res.data.msg)
            return;
        }
        setData(res.data.data.records)
        setTotal(res.data.data.length)
    }

    async function exportData() {
        // let session_id = sessionStorage.getItem('odoo_token')

        const response = await reqOdooExport({
            ...filterData
        })
        if (response && response.data.data) {
            if (response.data.data instanceof Array) {
                message.error(response.data.message)
                return;
            }
            window.location.href = response.data.data.url
        } else {
            message.error(response.data.message)
            return;
        }

    }

    // async function loginOdoo() {

    //     const response = await reqOdooLogin({
    //         "jsonrpc": "2.0",
    //         "id": "",
    //         "params": {
    //             "db": "oerp",
    //             "login": "songjifei",
    //             "password": "654321"
    //         }
    //     });
    //     let session_id = response.data.result.data.session_id
    //     sessionStorage.setItem('odoo_token', session_id)

    //     loadData()
    // }

    // useEffect(() => {
    //     if (!sessionStorage.getItem('odoo_token')) {
    //         loginOdoo()
    //     }


    //     return () => {
    //         console.log('unmount')
    //     }
    // }, [])

    useEffect(() => {
        // if (sessionStorage.getItem('odoo_token')) {
        //     loadData()
        // }
        loadData()

        return () => {
            console.log('unmount')
        }
    }, [current, size])
    const onChange = function (page, pagesize) {
        if (page == 0) {
            page = 1
        }
        setCurrent(page)
        setSize(pagesize)
    }
    const sizeChange = function (page, pagesize) {

        console.log(page, pagesize)
        if (page == 0) {
            page = 1
        }
        setSize(pagesize)
        setCurrent(page)
    }

    const style = { padding: '10px' }
    const dateFormat = 'YYYY-MM-DD';
    const sendDateChange = function (dates, dateStrings) {
        let tmpdata = { ...filterData, start_date: dateStrings[0], end_date: dateStrings[1] }
        setFilterData(tmpdata)
        console.log(filterData, 'filterData 为什么不会及时获取到呢 但是数据显示没问题 https://blog.csdn.net/aminwangaa/article/details/107379851')
    }
    const onFilter = function () {
        console.log(filterData)
        loadData()
    }
    const onExport = function () {
        exportData()
    }
    const disabledDate = (current) => {
        return current && current < moment('2020-08-01', "YYYYMMDD");
    }
    return (
        <div>
            <Row>
                <Col span={24}>
                    <div style={style}>
                        单据日期：<RangePicker onChange={sendDateChange} disabledDate={disabledDate} defaultValue={[moment(filterData.start_date, dateFormat), moment(filterData.end_date, dateFormat)]}/>

                        <Button type="primary" style={{ marginRight: 20, marginLeft: 20 }} onClick={onFilter}>查询</Button>
                        <Button type="primary" onClick={onExport}>导出</Button>
                    </div>
                </Col>
            </Row>
            <Card>
                <Table rowKey={(record, index) => record.tmpkey} bordered dataSource={data} columns={columns} loading={loading} pagination={false} scroll={{ x: 1400 }} />
                <div className="pagenation">
                    <Pagination
                        showQuickJumper
                        showSizeChanger
                        defaultCurrent={1}
                        showTotal={total => `总共 ${total} 条`}
                        total={total}
                        pageSize={size}
                        onShowSizeChange={sizeChange}
                        onChange={onChange}
                    />
                </div>

            </Card>
        </div>
    )
}

export default Odoo
