import request from '../utils/requestUtil'

export function reqHome() {
    return request.get('/sale/outstock/statistics')
}

export function reqSquare(obj) {
    return request.post('/sale/out/brand/amount',{...obj,companies:localStorage.getItem('company')})
}

export function reqLianqun(obj) {
    return request.post('/sale/out/chain/amount',{...obj,companies:localStorage.getItem('company')})
}

export function reqXiaowei(obj) {
    return request.post('sale/out/xw/amount',{...obj})
}

export function reqCompany() {
    return request.post('/get/companies')
}