const menuList = [
    {
        title: '首页',
        key: '/home',
        icon: 'AppstoreOutlined',
        isPublic: true
    },
    {
        title: '销售报表',
        key: '/charts',
        icon: 'MailOutlined',
        children: [
            {
                title: '出库总金额',
                key: '/outtotalmoney',
                icon: 'MenuFoldOutlined'
            },
            {
                title: '商品销售额',
                key: '/goodssalemoney',
                icon: 'PieChartOutlined'
            },
            {
                title: '商品销售量',
                key: '/goodssaleamount',
                icon: 'ContainerOutlined'
            },
            {
                title: '客户销售额',
                key: '/customsalemoney',
                icon: 'PieChartOutlined'
            },
        ]
    },
    {
        title: '订单信息',
        key: '/products',
        icon: 'MenuUnfoldOutlined',
        children: [
            {
                title: '销售退货',
                key: '/salereturn',
                icon: 'PieChartOutlined'
            },
            {
                title: '销售出库汇总',
                key: '/saleorder',
                icon: 'MenuFoldOutlined'
            },
            {
                title: '销售额统计',
                key: '/saletotal',
                icon: 'MailOutlined'
            },
            {
                title: '订单明细',
                key: '/orderdetail',
                icon: 'PieChartOutlined'
            },
            {
                title: '销售员订单产品统计',
                key: '/odoo',
                icon: 'ContainerOutlined'
            },
            {
                title: '服务型产品发货统计',
                key: '/prototal',
                icon: 'MenuFoldOutlined'
            },
            {
                title: '产品出库统计',
                key: '/productout',
                icon: 'BarChartOutlined'
            }
        ]
    },
    // {
    //     title: '用户管理',
    //     key: '/user',
    //     icon: 'DesktopOutlined'
    // },
    // {
    //     title: '角色管理',
    //     key: '/role',
    //     icon: 'ContainerOutlined'
    // },
    // {
    //     title: '图形图标',
    //     key: '/charts',
    //     icon: 'MailOutlined',
    //     children: [
    //         {
    //             title: '柱状图',
    //             key: '/bar',
    //             icon: 'MenuFoldOutlined'
    //         },
    //         {
    //             title: '折线图',
    //             key: '/line',
    //             icon: 'PieChartOutlined'
    //         },
    //         {
    //             title: '饼状图',
    //             key: '/pie',
    //             icon: 'ContainerOutlined'
    //         }
    //     ]
    // }
]

export default menuList